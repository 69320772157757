import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { isEmpty } from "lodash";
import { Redirect } from "react-router-dom";

import AdvertSkeleton from "../applicant/components/comm/AdvertSkeleton";
import NoResults from "../applicant/components/comm/NoResults";
import ReactPaginate from "react-paginate";

import { getAdvertisements } from "../../store/applicant/actions";
import AdvertisementItem from "../applicant/components/AdvertisementItem";
import { Typography } from "@mui/material";
import RightSideContent from "./components/RightSideContent";

const Home = (props) => {
  const { loading, onWait, user, advertisements, getAdvertisements } = props;

  const [searchInput, setSearchInput] = useState("");
  const [filteredJobs, setFilteredJobs] = useState([]);

  useEffect(() => {
    if (!advertisements.length) getAdvertisements("open");
  }, []);

  // ### PAGINATION
  // const itemsPerPage = 10;
  // const [itemOffset, setItemOffset] = useState(0);
  // const endOffset = itemOffset + itemsPerPage;
  // const [paginatedJobs, setPaginatedJobs] = useState([]);
  // const [pageCount, setPageCount] = useState(1);

  // useEffect(() => {
  //   setPaginatedJobs(filteredJobs.slice(itemOffset, endOffset));
  //   setPageCount(
  //     Math.ceil(
  //       filteredJobs.filter((item) => !item.isTimeOut).length / itemsPerPage
  //     )
  //   );
  // }, [filteredJobs, itemOffset, endOffset]);

  // const handlePageClick = (event) => {
  //   const newOffset =
  //     (event?.selected * itemsPerPage) %
  //     (filteredJobs ? filteredJobs.length : 1);
  //   setItemOffset(newOffset);
  // };

  useEffect(() => {
    if (advertisements) {
      const data = advertisements.filter((item) => !item.isTimeOut);

      if (searchInput !== "") {
        const value = searchInput?.toLowerCase();
        setFilteredJobs(
          data.filter((item) =>
            (
              (item.positionName || "") +
              (item.entityName || "") +
              (item.entityAcronym || "")
            )
              .replace(/\s/g, "")
              .toLowerCase()
              .includes(value.replace(/\s/g, "").toLowerCase())
          )
        );
      } else {
        setFilteredJobs(data);
      }
    }
  }, [advertisements, searchInput]);

  if (!isEmpty(user)) return <Redirect push to="/applicant/advertisements" />;

  const randomArray = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 10)
  );

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div
          className="card "
          style={{ borderRadius: "0px", backgroundColor: "#078ece" }}
        >
          <div className="card-body pt-5" style={{ borderRadius: "0px" }}>
            <div className="row">
              <div className="col text-center">
                <p
                  className="text-uppercase text-center"
                  style={{
                    fontWeight: "50",
                    color: "#f1f1f1",
                    fontSize: "xx-large",
                  }}
                >
                  Welcome to Rwanda Civil Service Recruitment Portal
                </p>

                <div className="row justify-content-center mt-5 mb-3">
                  <div className="form-group has-search col-12 col-md-8 col-lg-4">
                    <span className="fa fa-search form-control-feedback"></span>
                    <input
                      type="search"
                      className={`search-input rounded border `}
                      placeholder="Search..."
                      value={searchInput}
                      onChange={(e) => setSearchInput(e.target.value)}
                      id="search-public-input"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row mx-0 justify-content-center">
        <div className="col-12 col-md-5 col-lg-3 order-sm-last px-1 px-sm-3">
          <RightSideContent />
        </div>

        <div className="col-12  col-md-7 col-lg-9 order-sm-first px-1 px-sm-3">
          <div className="card mb-3 elevated rounded border border-primary mt-4 bg-light">
            <div className="card-header bg-light rounded-top font-weight-bold text-uppercase">
              <Typography variant="h6">
                New Job Advertisements ({advertisements.length})
              </Typography>
            </div>
            <div className="card-body">
              {loading && !onWait && !advertisements.length ? (
                randomArray.map((item) => (
                  <div className="mb-3" key={item + Math.random() * 11}>
                    <AdvertSkeleton />
                  </div>
                ))
              ) : filteredJobs.length ? (
                filteredJobs.map((advertisement, index) => (
                  <div className="mb-3" key={advertisement.id}>
                    <AdvertisementItem
                      advertisement={advertisement}
                      isHome
                      totalQuestions={0}
                    />
                  </div>
                ))
              ) : (
                <NoResults />
              )}
            </div>

            {/* {advertisements?.length >= itemsPerPage && (
              <div
                className="justify-content-center col-12 mb-3"
                style={{ overflow: "hidden" }}
              >
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      <span className="pr-1 d-none d-md-inline">Next</span>
                      <i className="fas fa-angle-double-right"></i>
                    </>
                  }
                  previousLabel={
                    <>
                      <i className="fas fa-angle-double-left"></i>
                      <span className="pl-1  d-none d-md-inline">Previous</span>
                    </>
                  }
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  onPageChange={handlePageClick}
                  pageCount={pageCount}
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                  nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                  activeLinkClassName="active"
                />
              </div>
            )} */}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = ({ loading, onWait, user, advertisements }) => {
  return { loading, onWait, user, advertisements };
};
export default connect(mapStateToProps, {
  getAdvertisements,
})(Home);
