import axios from "axios";

export const checkApplicantSessionStatus = async (
  advertisementId,
  language,
  callBack
) => {
  callBack(null);
  const selectedLanguage =
    language.toLowerCase() === "english"
      ? "en"
      : language.toLowerCase() === "Francais"
      ? "fr"
      : "kn";
  try {
    const res = await axios.get(
      `/api/psychometric-test/check_applicant_session_status/${advertisementId}/${selectedLanguage}`
    );
    if (res.data) {
      callBack(res.data);
    }
  } catch (error) {
    callBack(null);
  }
};

export default {
  checkApplicantSessionStatus,
};
