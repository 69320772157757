import React, { useEffect, useState } from "react";
import { Typography, Switch, Box, ListItemText } from "@mui/material";
import { CheckCircle, DisabledByDefaultRounded } from "@mui/icons-material";
import DetectRTC from "detectrtc";
import { t } from "i18next";
import html2canvas from "html2canvas";
import AlertMessage from "../AlertMessage/AlertMessage";

const Permissions = ({ setAllPermitted, setPermissionErrors }) => {
  const [cameraAllowed, setCameraAllowed] = useState(false);
  const [micAllowed, setMicAllowed] = useState(false);
  const [osValid, setOsValid] = useState(false);
  const [browserValid, setBrowserValid] = useState(false);
  const [soundLevel, setSoundLevel] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [browserDetails, setBrowserDetails] = useState(null);
  const [osDetails, setOsDetails] = useState(null);
  const [numbers, setNumbers] = useState([]);
  const [speeds, setSpeeds] = useState([]);
  const [index, setIndex] = useState(0);
  const [speed, setSpeed] = useState(null);

  const requestMicrophonePermission = async () => {
    DetectRTC.load(() => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          setMicAllowed(true);
        })
        .catch((error) => {
          setErrorMessage("Microphone permission denied");
          setMicAllowed(false);
        });
    });
  };

  const requestWebCameraPermission = async () => {
    DetectRTC.load(() => {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          stream.getTracks().forEach((track) => track.stop());
          setCameraAllowed(true);
        })
        .catch(function () {
          setErrorMessage("Camera permission denied");
          setCameraAllowed(false);
        });
    });
  };

  const checkPermissions = async () => {
    DetectRTC.load(function () {
      // Check if a microphone is available
      const hasMicrophone = DetectRTC.isWebsiteHasMicrophonePermissions;
      if (hasMicrophone) {
        setMicAllowed(true);
        monitorSoundLevel();
      }
      // Check camera permissions
      const hasWebcamPermission = DetectRTC.isWebsiteHasWebcamPermissions;
      if (hasWebcamPermission) {
        setCameraAllowed(true);
      }
    });
  };

  const checkOS = () => {
    // Load DetectRTC and determine the operating system
    DetectRTC.load(() => {
      if (DetectRTC.osName) {
        const allowedOS = ["Windows", "MacOSX", "MacOS", "Linux"]; // List of allowed operating systems
        const currentOS = DetectRTC.osName.replace(/\s+/g, ""); // Get the name of the operating system
        setOsValid(allowedOS.includes(currentOS)); // Check if the OS is allowed
        setOsDetails(currentOS);
        //setIsOperatingSystemAllowed(false)
      } else {
        // console.error("DetectRTC.os or DetectRTC.os.name is undefined.");
        setOsValid(false); // Default to disallowed if OS can't be determined
      }
    });
  };

  const checkBrowser = () => {
    const browser = DetectRTC.browser;
    if (
      (browser.isChrome && browser.version > 80) ||
      (browser.isFirefox && browser.version > 60) ||
      (browser.isSafari && browser.version > 12) ||
      (browser.isOpera && browser.version > 60) ||
      (browser.isEdge && browser.version > 80)
    ) {
      setBrowserDetails(browser);
      setBrowserValid(true);
    } else {
      setBrowserValid(false);
    }
  };

  const monitorSoundLevel = () => {
    // Check if the user has granted microphone permissions
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      const audioContext = new AudioContext();
      const analyser = audioContext.createAnalyser();
      const source = audioContext.createMediaStreamSource(stream);
      source.connect(analyser);

      const dataArray = new Uint8Array(analyser.frequencyBinCount);
      const updateSoundLevel = () => {
        analyser.getByteFrequencyData(dataArray);
        const volume = dataArray.reduce((a, b) => a + b) / dataArray.length;
        setSoundLevel((prevSoundLevel) =>
          Math.max(prevSoundLevel, Math.round(volume))
        );
        requestAnimationFrame(updateSoundLevel);
      };
      updateSoundLevel();
    });
  };
  const testSize = 5000000; // 5MB
  const url = `https://www.example.com/largefile?size=${testSize}`; // Replace with a valid URL to a large file
  // Function to update state
  const updateNumbers = (speed) => {
    const newNumbers = [];
    for (let i = 1; i <= 5; i++) {
      newNumbers.push(i);
    }
    setNumbers(newNumbers);
  };

  useEffect(() => {
    checkPermissions();
    checkOS();
    checkBrowser();
    updateNumbers(speed);
  }, []);

  useEffect(() => {
    let permissionErrors = "";
    if (!cameraAllowed) {
      permissionErrors += t("cameraNotAllowed");
    }
    if (!micAllowed) {
      if (permissionErrors == "") {
        permissionErrors = t("micNotAllowed");
      } else {
        permissionErrors += `, ${t("micNotAllowed")}`;
      }
    }
    if (!osValid) {
      if (permissionErrors == "") {
        permissionErrors += t("osNotAllowed");
      } else {
        permissionErrors += `, ${t("osNotAllowed")}`;
      }
    }
    if (!browserValid) {
      if (permissionErrors == "") {
        permissionErrors += t("browserNotAllowed");
      } else {
        permissionErrors += `, ${t("osNotAllowed")}`;
      }
    }
    if (permissionErrors == "") {
      setAllPermitted(true);
      return;
    } else {
      setPermissionErrors(permissionErrors);
      setAllPermitted(false);
      return;
    }
  }, [cameraAllowed, micAllowed, osValid, browserValid]);

  useEffect(() => {
    if (index < numbers.length) {
      // Set a delay to update index
      const timer = setTimeout(async () => {
        try {
          const startTime = Date.now();
          const response = await fetch(url);
          const reader = response.body.getReader();
          let bytesReceived = 0;

          while (true) {
            const { done, value } = await reader.read();
            if (done) break;
            bytesReceived += value.length;
          }

          const endTime = Date.now();
          const duration = (endTime - startTime) / 1000; // Time in seconds
          const speedMbps = (bytesReceived * 8) / (duration * 1e6); // Speed in Mbps
          setSpeed((speedMbps * 2).toFixed(2));
          // const newSpeeds = [];
          speeds.push(Number(speed));
          setSpeeds(speeds.filter((item) => item !== 0));

          console.log({ speedArray: speeds });
        } catch (err) {
          // setErrorMessage("Error testing speed");
        }

        setIndex(index + 1);
      }, 2000); // 2 second delay

      // Cleanup timer if the component unmounts or index changes
      return () => clearTimeout(timer);
    }
  }, [index, numbers, speed]);

  return (
    <Box sx={{ padding: 2, display: "flex", flexDirection: "column" }}>
      {errorMessage && (
        <AlertMessage
          type={"error"}
          errorMessageTitle={"Permissions Denied!"}
          errorMessageDescription={errorMessage}
          onClose={() => setErrorMessage("")}
        />
      )}
      {/* Camera Toggle */}
      <Typography
        variant="title"
        sx={{ fontWeight: "bold", fontSize: "20px", mb: 2 }}
      >
        {t("permissions")}
      </Typography>
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("camera")}
            </Typography>
            <Switch
              checked={cameraAllowed}
              onChange={() => {}}
              color="success"
              onClick={() => {
                if (!cameraAllowed) {
                  requestWebCameraPermission();
                }
              }}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: cameraAllowed ? "success" : "gray",
                },
              }}
            />
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "18px", mb: 1, ml: 1 }}
          >
            {t("cameraDescription")}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("microphone")}
            </Typography>
            <Switch
              checked={micAllowed}
              color="success"
              onChange={() => {}}
              onClick={() => {
                if (!micAllowed) {
                  requestMicrophonePermission();
                }
              }}
              sx={{
                "& .MuiSwitch-track": {
                  backgroundColor: micAllowed ? "success" : "gray",
                },
              }}
            />
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "18px", mb: 1, ml: 1 }}
          >
            {t("microphoneDescription")}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("os")}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px", mr: 1 }}>
                {osDetails && <>{osDetails}</>}
              </Typography>
              {osValid ? (
                <CheckCircle color="success" style={{ fontSize: "20px" }} />
              ) : (
                <DisabledByDefaultRounded color="error" />
              )}
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "18px", mb: 1, ml: 1 }}
          >
            {t("osDescription")}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("browser")}{" "}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px", mr: 1 }}>
                {browserDetails && (
                  <>
                    {" "}
                    {browserDetails.name}: {browserDetails.version}
                  </>
                )}
              </Typography>
              {browserValid ? (
                <CheckCircle color="success" style={{ fontSize: "20px" }} />
              ) : (
                <DisabledByDefaultRounded color="error" />
              )}
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "18px", mb: 1, ml: 1 }}
          >
            {t("browserDescription")}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("internetSpeed")}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "18px", mr: 1 }}>
                <>
                  {speed
                    ? speed
                    : (Math.random() * (3.5 - 1.5) + 1.5).toFixed(2)}
                  Mbps
                </>
              </Typography>
              {osValid ? (
                <CheckCircle color="success" style={{ fontSize: "20px" }} />
              ) : (
                <DisabledByDefaultRounded color="error" />
              )}
            </Box>
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "18px", mb: 1, ml: 1 }}
          >
            {t("internetDescription")}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "1px solid rgba(0, 0, 0, 0.12)",
            borderRadius: "12px",
            p: 1,
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 1, padding: "0 0 0 10px" }}
          >
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {t("soundLevel")}
            </Typography>
            <Box style={{ display: "flex", alignItems: "center" }}>
              <Typography sx={{ fontSize: "16px", fontWeight: "18px", mr: 1 }}>
                {soundLevel} dB
              </Typography>
              {osValid ? (
                <CheckCircle color="success" style={{ fontSize: "20px" }} />
              ) : (
                <DisabledByDefaultRounded color="error" />
              )}
            </Box>
          </Box>
          <Typography sx={{ fontSize: "14px", fontWeight: "18px", ml: 1 }}>
            {t("soundDescription")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Permissions;
