import types from "./action-types";
import { defaultState } from "./state";

const env = (envState = defaultState.env, action) => {
  switch (action.type) {
    case types.SET_ENV:
      return action.data;

    default:
      return envState;
  }
};

const loading = (loadingState = defaultState.loading, action) => {
  switch (action.type) {
    case types.START_LOADING:
      return true;

    case types.END_LOADING:
      return false;

    case types.CLEAN_STATE:
      return defaultState.loading;

    case types.LOGOUT:
      return defaultState.loading;

    default:
      return loadingState;
  }
};

const onWait = (waitState = defaultState.onWait, action) => {
  switch (action.type) {
    case types.START_WAIT:
      return true;

    case types.END_WAIT:
      return false;

    case types.CLEAN_STATE:
      return defaultState.onWait;

    default:
      return waitState;
  }
};

const user = (userState = defaultState.user, action) => {
  switch (action.type) {
    case types.SET_USER:
      return action.data;
    case types.SET_EDUCATION:
      return {
        ...userState,
        profileStatus: { ...userState.profileStatus, education: action.data },
      };
    case types.SET_EXPERIENCE:
      return {
        ...userState,
        profileStatus: { ...userState.profileStatus, experience: action.data },
      };
    case types.SET_LANGUAGE:
      return {
        ...userState,
        profileStatus: { ...userState.profileStatus, language: action.data },
      };
    case types.SET_DISABILITY:
      return {
        ...userState,
        profileStatus: { ...userState.profileStatus, disability: action.data },
      };
    case types.SET_CERTIFICATE:
      return {
        ...userState,
        profileStatus: { ...userState.profileStatus, certificate: action.data },
      };
    case types.SET_REFEREE:
      return {
        ...userState,
        profileStatus: {
          ...userState.profileStatus,
          referee1: action.data >= 1 ? true : false,
          referee2: action.data >= 2 ? true : false,
          referee3: action.data >= 3 ? true : false,
        },
      };
    case types.LOGOUT:
      return defaultState.user;

    default:
      return userState;
  }
};

const isFetchingUser = (state = defaultState.fetchingUser, action) => {
  switch (action.type) {
    case types.END_LOADING:
      return false;

    default:
      return state;
  }
};

export default {
  env,
  loading,
  onWait,
  user,
  isFetchingUser,
};
