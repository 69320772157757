import { Card, CardContent, Skeleton, Typography } from "@mui/material";
import React from "react";

function AdvertSkeleton() {
  return (
    <Card className={`border rounded w-full h-100 bg-light mb-3`} elevation={1}>
      <CardContent>
        {/* Cost Center Name */}
        <Typography sx={{ fontSize: 13 }} variant="h6">
          <Skeleton />
        </Typography>
        {/* position name */}
        <div className="">
          <Typography variant="h6" component="div" sx={{ fontSize: 14 }}>
            <Skeleton />
          </Typography>
          {/* employee Group Name */}
          {/* <span className="badge px-0" style={{ fontSize: 14, width: "70px" }}>
            <Skeleton />
          </span> */}
        </div>
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="d-flex">
            {/* posted date */}
            <div className="d-flex flex-column mr-3">
              <div style={{ fontSize: 14, width: "80px" }}>
                <Skeleton />
                <Typography
                  variant="caption"
                  style={{ fontSize: 14, width: "50px" }}
                >
                  <Skeleton />
                </Typography>
              </div>
              <Typography
                variant="body2"
                className="font-weight-bold"
                style={{ fontSize: 14, width: "90px" }}
              >
                <Skeleton />
              </Typography>
            </div>

            {/* deadline date */}
            <div className="d-flex flex-column mr-3">
              <div style={{ fontSize: 14, width: "70px" }}>
                <Skeleton />
                <Typography variant="caption">
                  <Skeleton />
                </Typography>
              </div>
              <Typography
                variant="body2"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                <Skeleton />
              </Typography>
            </div>

            {/* Number of post */}
            <div className="mr-3">
              <Typography variant="caption">
                <Skeleton style={{ fontSize: 14, width: "40px" }} />
              </Typography>
              <Typography variant="caption">
                <Skeleton style={{ fontSize: 14, width: "60px" }} />
              </Typography>
            </div>

            {/* level of position */}
            {/* <div>
              <Typography variant="caption">
                <Skeleton style={{ fontSize: 14, width: "40px" }} />
              </Typography>
              <Typography variant="caption">
                <Skeleton style={{ fontSize: 14, width: "30px" }} />
              </Typography>
            </div> */}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

export default AdvertSkeleton;
