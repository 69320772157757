import React, { useState } from "react";
import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import { Padding } from "@mui/icons-material";

const LanguageStep = ({
  handleSetLanguage,
  examLanguages,
  selectedLanguage,
  t,
  isLoading,
  sessionExists,
}) => {
  const [isLoadingTest, setIsLoadingTest] = useState(isLoading);
  const ExamLanguages = Array.isArray(examLanguages)
    ? examLanguages
    : [{ name: examLanguages }];

  return (
    <div>
      {isLoadingTest === true ? (
        <div
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            width: "100%",
          }}
        >
          <div className="spinner-border" role="status"></div>
          <div>Loading, Please wait...</div>
        </div>
      ) : (
        <>

          {sessionExists &&
            <div style={{Padding: "40px", width: "100%", textAlign: "center", paddingTop: "100px"}}><h3>{t("welcomeBack")}</h3></div>
          }
          <div
            style={{
              fontSize: "16px",
            }}
          >
            {sessionExists ? (<><div style={{textAlign: "center"}}><h5>{t("welcomeBack1")} {selectedLanguage} {t("welcomeBack2")}</h5><br/>{t("welcomeBack3")}</div></>):t("chooseLanguage")}
          </div>
          <div style={{ height: "20px" }}></div>
          {!sessionExists &&
            <RadioGroup
              value={selectedLanguage}
              style={{ fontSize: "8px" }}
              onChange={(e) =>
                //selectedLanguage=selectedLanguage
                // handleLanguageChange(e.target.value, (loadingTest) => {
                //   console.log({ startingnthetest: "form step 1" });
                //   setIsLoadingTest(loadingTest);
                // })
                handleSetLanguage(e.target.value, (loadingTest) => {
                  console.log({ startingnthetest: "form step 1" });
                  setIsLoadingTest(loadingTest);
                })
              }
            >
              {ExamLanguages.sort((a, b) => a.name.localeCompare(b.name)).map(
                (language, index) => (
                  <FormControlLabel
                    key={index}
                    value={language.name}
                    control={<Radio />}
                    label={
                      language.name == "Francais" ? "Français" : language.name
                    }
                    disabled={sessionExists} // Disable if sessionExists is true
                  />
                )
              )}
            </RadioGroup>
          }
        </>
      )}
    </div>
  );
};

export default LanguageStep;
