import { Button } from "@mui/material";
import React, { Fragment, useState } from "react";
import Webcam from "react-webcam";
import "./validate.css";
import { Container, Row, Col } from "react-bootstrap";
import SystemCheck from "../systemcheck/systemCheeck";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import axios from "axios";
import { checkCameraPermissions } from "../../helper/checkCameraPermission";
import { useTranslation } from "react-i18next";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { CheckCircle } from "@mui/icons-material";
import AlertMessage from "../AlertMessage/AlertMessage";
import niddetect from "../../../../assets/images/niddetect.jpg";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

const ValidatePage = ({
  imageSrc,
  setImageSrc,
  step,
  callBack,
  setIsOperatingSystemAllowed,
  confirmStartTest,
  setConfirmStartTest,
}) => {
  var buttonfield = true;
  if (document.addEventListener) {
    document.addEventListener(
      "contextmenu",
      function (e) {
        e.preventDefault();
      },
      false
    );
  }
  const webcamRef = React.useRef(null);
  const [imgSrc, setImgSrc] = React.useState(imageSrc);
  const [result, setResult] = useState(null);
  const [resultMessage, setResultMessage] = useState(null);
  const [cameraStatus, setCameraStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();

  const capture = React.useCallback(async () => {
    setErrorMessage("");
    setIsLoading(true);
    setImageSrc(null);
    const imageSrc = webcamRef.current.getScreenshot();
    const hasPermission = await checkCameraPermissions();
    setCameraStatus(hasPermission);

    if (imageSrc !== null) {
      setImgSrc(imageSrc);
      sessionStorage.setItem("imageSrc", imageSrc); //store image in session storage
      // Optionally log to debug
      await detectPersonInImage(imageSrc);
    } else {
      setIsLoading(false);
      setErrorMessage(
        "Failed to capture image. Please check for camera permissions."
      );
      console.error("Failed to capture image.");
    }
  }, [webcamRef, setImgSrc]);

  const detectPersonInImage = async (imageSelected) => {
    if (!imageSelected) return;

    const imgElement = new Image();
    imgElement.src = imageSelected;
    imgElement.onload = async () => {
      const model = await cocoSsd.load();
      const predictions = await model.detect(imgElement);
      // Count the number of "person" detections
      const personCount = predictions.filter(
        (pred) => pred.class === "person"
      ).length;

      const personDetected = predictions.some(
        (pred) => pred.class === "person"
      );
      if (personDetected === true) {
        //setResult(true);
        if (personCount === 1 && cameraStatus) {
          setResult(true);
          setResultMessage(t("personDetected"));
          setImageSrc(imageSelected);
        } else if (personCount > 1) {
          setResultMessage(t("moreThanOne"));
          setResult(false);
        } else {
          setResultMessage(t("noDetected"));
          setResult(false);
        }
        setIsLoading(false);
      } else {
        setResultMessage(t("noDetected"));
        setResult(false);
        setIsLoading(false);
      }
    };

    imgElement.onerror = () => {
      setResult(false);
      console.log({ resultafter3243: result });
    };
  };

  const sessionLog = async (
    advertisementId,
    action,
    comment,
    applicantImage
  ) => {
    const apidata = { advertisementId, action, comment, applicantImage };
    const { data: response } = await axios.post(
      "/api/psychometric-test/sessionlog",
      apidata
    );
    if (response) {
      console.log(response);
    }
  };

  if (imgSrc) {
    buttonfield = false;
  }

  return (
    <Fragment>
      <div className="App-header">
        <Container fluid>
          <>
            <Row>
              
              <Col
                sm={6}
                style={{
                  paddingLeft: 0,
                  paddingRight: 0,
                  flex: 1,
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <center>
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                      width: "500px",
                      height: "350px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.06)",
                      backgroundColor: "#fff",
                    }}
                  >
                    {/* Webcam Component */}
                    <Webcam
                      ref={webcamRef}
                      screenshotFormat="image/jpeg"
                      style={{
                        width: "100%",
                        height: "100%",
                        borderRadius: "10px",
                        objectFit: "cover",
                      }}
                    />
                    {/* Blinking Red Dot */}
                    <div
                      style={{
                        position: "absolute",
                        top: "15px",
                        right: "15px",
                        width: "15px",
                        height: "15px",
                        borderRadius: "50%",
                        backgroundColor: "red",
                        animation: imgSrc ? "none" : "blink 1s infinite", // Stop blinking if imgSrc exists
                      }}
                    />
                    {/* CSS for animation */}
                    <style>
                      {`
                        @keyframes blink {
                          0%, 100% {
                            opacity: 1;
                          }
                          50% {
                            opacity: 0;
                          }
                        }
                      `}
                    </style>
                  </div>


                  {/* <div
                    style={{
                      position: "absolute",
                      left: 138,
                      top: 40,
                      marginLeft: "80px",
                    }}
                  ></div> */}
                {isLoading === true && (
                  <div className="bg-primary">
                    <i
                      className="fas fa-spinner loaderIcon text-primary loader2"
                      style={{ fontSize: "44px", top: 150 }}
                    ></i>
                  </div>
                )}
                <br/>
                <Button
                  id="validateButtons"
                  variant="outlined"
                  color="primary"
                  onClick={capture}
                  style={{
                    marginTop: "7px",
                    borderRadius: "48px",
                  }}
                >
                  {t("detectFace")}
                </Button>

                </center>
              </Col>
              <Col sm={6}>
                {imgSrc ? (
                  <>
                    <center>
                      {imgSrc && (
                        <img
                          src={imgSrc}
                          style={{ width: "500px", borderRadius: "10px", height: "350px" }}
                        />
                      )}
                      {imgSrc && (
                        <div className="overlay">
                          <p className="image-text">
                            {result === null ? (
                              imageSrc === null && (
                                <span
                                  style={{
                                    color: "orange",
                                    backgroundColor: "white",
                                    padding: "0 14px",
                                    borderRadius: "10px",
                                  }}
                                >
                                  {t("detecting")}
                                </span>
                              )
                            ) : result === true ? (
                              <span
                                style={{
                                  color: "green",
                                  backgroundColor: "white",
                                  padding: "0 14px",
                                  borderRadius: "10px",
                                }}
                              >
                                {resultMessage}
                              </span>
                            ) : (
                              <span
                                style={{
                                  color: "red",
                                  backgroundColor: "white",
                                  padding: "0 14px",
                                  borderRadius: "10px",
                                }}
                              >
                                {resultMessage}
                              </span>
                            )}
                          </p>
                        </div>
                      )}
                    </center>
                  </>
                ):
                  <>
                    <center>
                      <div
                        style={{
                          width: "500px",
                          height: "350px",
                          borderRadius: "10px",
                          backgroundImage: `url(${niddetect})`, // Set the background image
                          backgroundSize: "cover", // Ensures the image covers the div
                          backgroundPosition: "center", // Centers the image
                          backgroundRepeat: "no-repeat", // Ensures the image doesn't repeat
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19)", // Adds elevation effect
                        }}
                      ></div>
                    </center>
                  </>
                }
              </Col>
              
            </Row>
            <Row>
              <Col sm={12} style={{ marginTop: "10px" }}>
                {errorMessage !== "" && (
                  <AlertMessage
                    type={"error"}
                    errorMessageTitle={"Permissions Denied!"}
                    errorMessageDescription={errorMessage}
                    onClose={() => setErrorMessage("")}
                  />
                )}
              </Col>
            </Row>
          </>
        </Container>
      </div>
      <div
        className=""
        style={{
          position: "fixed",
          top: "20",
          heigh: "200px",
          width: "200px",
          backgroundColor: "red",
        }}
      ></div>
      <Dialog
        open={confirmStartTest}
        onClose={() => setConfirmStartTest(false)}
        BackdropProps={{
          style: {
            backdropFilter: "blur(8px)", // Adjust the blur amount
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent black background
          },
        }}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "16px",
            padding: "16px",
          },
        }}
      >
        <DialogTitle>{t("startExam")}</DialogTitle>
        <DialogContent>{t("proctoringConcent")}</DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            color="warning"
            onClick={() => setConfirmStartTest(false)}
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              borderRadius: "20px",
            }}
          >
            {t("noCancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setConfirmStartTest(true)}
            style={{
              paddingLeft: "20px",
              paddingRight: "20px",
              borderRadius: "20px",
            }}
          >
            {t("yesStart")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
};

export default ValidatePage;
