import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { Warning as WarningIcon } from "@mui/icons-material";
import { styled } from "@mui/system";

const BlurredOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backdropFilter: "blur(8px)",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 1000,
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "20px",
    border: `2px solid ${theme.palette.warning}`,
    padding: theme.spacing(2),
    boxShadow: `0 8px 20px rgba(0, 0, 0, 0.2)`,
  },
}));

const ConfirmationDialog = ({
  title,
  description,
  isOpen,
  onClose,
  onConfirm,
}) => {
  return (
    <div>
      {isOpen && <BlurredOverlay />}
      <StyledDialog open={isOpen} onClose={onClose} maxWidth="xs" fullWidth>
        <DialogTitle>
          <IconButton color="warning" sx={{ mr: 1 }}>
            <WarningIcon />
          </IconButton>
          <Typography variant="h6" component="span" color="warning">
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography color="textSecondary">{description}</Typography>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onClose}
            variant="outlined"
            color="info"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "20px",
            }}
          >
            Cancel
          </Button>
          <Button
            onClick={onConfirm}
            color="warning"
            variant="contained"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              borderRadius: "20px",
            }}
          >
            Yes, Submit
          </Button>
        </DialogActions>
      </StyledDialog>
    </div>
  );
};

export default ConfirmationDialog;
