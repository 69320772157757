import axios from "axios";

const sessionLog = async (advertisementId, action, comment, applicantImage) => {
  const apidata = { advertisementId, action, comment, applicantImage };
  const { data: response } = await axios.post(
    "/api/psychometric-test/sessionlog",
    apidata
  );
  if (response) {
    console.log(response);
  }
};

export default sessionLog;
