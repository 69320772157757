import React from "react";
import ValidatePage from "../systemvalidation/ValidatePage";
import { Box, List, ListItem, ListItemText, Typography } from "@mui/material";
import {
  CameraAltOutlined,
  ContactEmergencyOutlined,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
// import { display } from "html2canvas/dist/types/css/property-descriptors/display";

const RuleSection = ({ title, icon, children }) => (
  <Box
    sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "12px", p: 1 }}
  >
    <Box display="flex" alignItems="center" sx={{ padding: "0 0 0 10px" }}>
      {icon && icon}
      <Typography
        variant="subtitle1"
        sx={{ ml: 2, fontWeight: "bold", color: "primary.main" }}
      >
        {title}
      </Typography>
    </Box>
    {children}
  </Box>
);

const FaceDetection = ({
  imageSrc,
  setImageSrc,
  step,
  callBack,
  setIsOperatingSystemAllowed,
  confirmStartTest,
  setConfirmStartTest,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      <Box
        elevation={1}
        sx={{
          p: 0,
          margin: "auto",
          overflowY: "auto",
        }}
      >
        <Box sx={{ mb: 3, ml: 1 }}>
          <Typography variant="title" sx={{ fontWeight: "bold", mb: 3, fontSize: "20px" }}>
            {t("detectFace")}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 3 }}>
            {t("cameraDetection")}
          </Typography>
        </Box>
        <Box
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "20px",
          }}
        >
          <RuleSection title={t("webAndMic")} icon={<CameraAltOutlined />}>
            <List dense>
              <ListItem>
                <ListItemText primary={t("webcamDesc")} />
              </ListItem>
            </List>
          </RuleSection>

          <RuleSection title={t("validId")} icon={<ContactEmergencyOutlined />}>
            <List dense>
              <ListItem>
                <ListItemText primary={t("validIdDesc")} />
              </ListItem>
            </List>
          </RuleSection>
        </Box>
        <Box
          sx={{
            mt: 3,
          }}
        >
          <ValidatePage
            imageSrc={imageSrc}
            setImageSrc={setImageSrc}
            step={step}
            callBack={callBack}
            setIsOperatingSystemAllowed={setIsOperatingSystemAllowed}
            confirmStartTest={confirmStartTest}
            setConfirmStartTest={setConfirmStartTest}
          />
        </Box>
      </Box>
    </div>
  );
};

export default FaceDetection;
