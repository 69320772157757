import React from "react";
import html2canvas from "html2canvas";

import {
  Typography,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
} from "@mui/material";
import {
  CheckCircleOutline as CheckIcon,
  ErrorOutline as ErrorIcon,
  ComputerOutlined as ComputerIcon,
  NetworkCheckOutlined as NetworkIcon,
  CameraAltOutlined as CameraIcon,
  SecurityOutlined as SecurityIcon,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";

const RuleSection = ({ title, icon, children }) => (
  <Box
    sx={{ border: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: "12px", p: 1 }}
  >
    <Box
      display="flex"
      alignItems="center"
      sx={{ mb: 1, padding: "0 0 0 10px" }}
    >
      {icon}
      <Typography
        variant="subtitle1"
        sx={{ ml: 2, fontWeight: "bold", color: "primary.main" }}
      >
        {title}
      </Typography>
    </Box>
    {children}
  </Box>
);

const ExamRules = () => {
  const { t } = useTranslation();

  return (
    <Box
      elevation={1}
      sx={{
        p: 0,
        margin: "auto",
        overflowY: "auto",
      }}
    >
      <Typography variant="title" sx={{ fontWeight: "bold", mb: 3 }}>
        {t("examRules")}
      </Typography>
      <Typography sx={{ fontSize: "14px", fontWeight: "18px", mb: 1 }}>
        {t("examDescription")}
      </Typography>
      <Box display="flex" alignItems="center" sx={{ mb: 4 }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          {t("examCondition")}
        </Typography>
      </Box>
      {/* Time Management */}
      <Box
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: "20px",
        }}
      >
        <RuleSection title={t("timeManagement")} icon={<SecurityIcon />}>
          <List dense>
            <ListItem>
              <ListItemText
                primary={t("timeManagementRuleT")}
                secondary={t("timeManagementRuleS")}
              />
            </ListItem>
            <ListItem>
              <ListItemText primary={""} secondary={t("timeManagementRule2")} />
            </ListItem>
          </List>
        </RuleSection>

        {/* Technical Requirements */}
        <RuleSection title={t("technicalRequirements")} icon={<ComputerIcon />}>
          <Box
            style={{
              padding: "0 0 0 24px",
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("supportedOs")}
            </Typography>
            <Box display="flex" gap={1} mb={2}>
              <Chip size="small" label="Windows 10/11" variant="outlined" />
              <Chip size="small" label="macOS 10.15+" variant="outlined" />
              <Chip size="small" label="Linux" variant="outlined" />
            </Box>

            <Typography variant="subtitle2" sx={{ fontWeight: "bold", mb: 1 }}>
              {t("supportedBrowser")}
            </Typography>
            <Box display="flex" gap={1} mb={1}>
              <Chip size="small" label="Chrome 95+" variant="outlined" />
              <Chip size="small" label="Firefox 90+" variant="outlined" />
              <Chip size="small" label="Edge (Chromium)" variant="outlined" />
              <Chip size="small" label="Safari 14+" variant="outlined" />
              <Chip size="small" label="Opera" variant="outlined" />
            </Box>
          </Box>
        </RuleSection>

        {/* Connectivity Requirements */}
        <RuleSection title={t("internetDevice")} icon={<NetworkIcon />}>
          <List dense>
            <ListItem>
              <ListItemText
                primary={t("minInternet")}
                secondary="2 Mbps download, 1 Mbps upload"
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("recommendedDevice")}
                secondary="Quad-core processor, 8GB RAM"
              />
            </ListItem>
            <Typography variant="h6" style={{ fontSize: "14px", color: "darkgreen", padding: "0 14px" }}>{t("computerToProjector")}</Typography>
          </List>
        </RuleSection>

        {/* Exam Environment */}
        <RuleSection title={t("examEnvironment")} icon={<CameraIcon />}>
          <List dense>
            <ListItem>
              <ListItemText
                primary={t("quiteSpaceT")}
                secondary={t("quiteSpaceS")}
              />
            </ListItem>
            <ListItem>
              <ListItemText
                primary={t("clearDeskT")}
                secondary={t("clearDeskS")}
              />
            </ListItem>
          </List>
        </RuleSection>
      </Box>

      <Box
        sx={{
          border: "1px solid rgba(0, 0, 0, 0.12)",
          borderRadius: "12px",
          backgroundColor: "",
          color: "darkgreen",
          p: 2,
          mt: 3,
        }}
      >
        <Box style={{ fontWeight: "bold", padding: "0 0 6px 0" }}>
          <Typography variant="title">{t("codeOfConduct")}</Typography>
        </Box>
        <Box style={{ fontSize: "14px" }}>{t("codeOfConduct1S")}</Box>
      </Box>
    </Box>
  );
};

export default ExamRules;
