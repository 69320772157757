import React, { useState, useEffect } from "react";

import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";

import { connect } from "react-redux";

import Advertisements from "./pages/Advertisements";
import Applications from "./pages/Applications";
import Test from "./psychometric-test/components/Test";

import { isEmpty } from "lodash";

const Index = (props) => {
  const { env, user, loading, isFetchingUser } = props;

  useEffect(() => {
    if (isEmpty(user)) return <Redirect to="/" />;
  }, []);

  return (
    <>
      {!isEmpty(user) && !isFetchingUser && (
        <div className="mt-2">
          <Switch>
            <Route
              path="/applicant/advertisements"
              component={Advertisements}
            />
            <Route path="/applicant/doing-exam" component={Test} />
            <Route path="/applicant/applications" component={Applications} />
            <Route
              path="/applicant"
              component={() => <Redirect to="/applicant/advertisements" />}
            />
          </Switch>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ user, loading, env, isFetchingUser }) => {
  return { user, loading, env, isFetchingUser };
};
export default connect(mapStateToProps, {})(Index);
