import axios from "axios";

export const submitProctoringTest = async (
  totalScore,
  totalQuestions,
  advertisement,
  callBack
) => {
  callBack(null);
  try {
    const res = await axios.post(`/api/psychometric-test/submitthtest`, {
      advertisementId: advertisement.id,
      passstatus: totalScore >= 50 ? 0 : 3, // if passed: 0 if failed 3
      score: totalScore,
    });
    if (res.data) {
      callBack(true);
    }
  } catch (error) {
    callBack(false);
  }
};

export default {
  submitProctoringTest,
};
