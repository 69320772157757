import React from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";

const AlertMessage = ({
  type, // error, success, warning, info
  errorMessageTitle,
  errorMessageDescription,
  onClose,
}) => {
  return (
    <Alert
      severity={type}
      variant="outlined"
      style={{
        width: "100%",
        height: "fit-content",
        backgroundColor:
          type === "error" || type === "warning" ? "#f2e1df" : "white",
        borderColor:
          type === "error" || type === "warning" ? "#f2e1df" : "white",
        borderRadius: "9px",
      }}
      onClose={onClose}
    >
      <AlertTitle style={{ fontWeight: "bolder" }}>
        {errorMessageTitle}
      </AlertTitle>
      {errorMessageDescription}
    </Alert>
  );
};

export default AlertMessage;
