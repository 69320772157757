import axios from "axios";
// Multiple, TrueFalse, FillInTheBlank
const saveMultipleChoiceAnswer = async (
  testSessionId,
  selectedQuestion,
  optionItem,
  score,
  res
) => {
  try {
    const theCorrectOption = selectedQuestion.options.find(
      (itm) => itm.isCorrect === true
    );
    const markedScore =
      score !== null
        ? score
        : optionItem.id === theCorrectOption?.id
        ? selectedQuestion.score
        : 0;
    const response = await axios.post("/api/psychometric-test/markaquestion", {
      testSessionId: testSessionId.toString(),
      questionId: selectedQuestion.id.toString(),
      score: markedScore.toString(),
      answer:
        optionItem?.optionNumber !== undefined
          ? optionItem?.optionNumber?.toString()
          : optionItem,
      isCorrect: (theCorrectOption === undefined || theCorrectOption === null
        ? 0
        : 1
      ).toString(),
      correctAnswer: theCorrectOption?.optionNumber
        ? theCorrectOption?.optionNumber?.toString()
        : theCorrectOption,
    });
    if (response) {
      res(false, {
        status: "success",
        message: "Question marked successfully",
      });
    }
  } catch (err) {
    res(false, {
      status: "error",
      message: "Please contact the administrator to continue",
    });
    console.error("Error processing the question:", err);
  }
};

// Matching question
const saveMatchingQuestionAnswers = async (
  testSessionId,
  selectedQuestion,
  optionItem,
  score,
  res
) => {
  try {
    const response = await axios.post("/api/psychometric-test/markaquestion", {
      testSessionId: testSessionId.toString(),
      questionId: selectedQuestion.id.toString(),
      score: score.toString(),
      isCorrect: (score > 0 ? 1 : 0).toString(),
      answer:
        optionItem === undefined ||
        optionItem === null ||
        optionItem.length === 0
          ? ""
          : JSON.stringify(optionItem), // This the new matching options
      correctAnswer: JSON.stringify(selectedQuestion.options), // This is the current provided matching
    });
    if (response) {
      res(false, {
        status: "success",
        message: "Question marked successfully",
      });
    }
  } catch (err) {
    res(false, {
      status: "error",
      message: "Please contact the administrator to continue",
    });
    console.error("Error processing the question:", err);
  }
};

export const saveQuestionAnswer = async (
  testSessionId,
  selectedQuestion,
  optionItem,
  score,
  res
) => {
  res(true, null);
  if (optionItem.id) {
    await saveMultipleChoiceAnswer(
      testSessionId,
      selectedQuestion,
      optionItem,
      score,
      res
    );
  } else {
    await saveMatchingQuestionAnswers(
      testSessionId,
      selectedQuestion,
      optionItem,
      score,
      res
    );
  }
};
