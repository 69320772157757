import { combineReducers } from "redux";

import commonReducers from "./comm/reducers";
import applicantReducers from "./applicant/reducers";

export default combineReducers({
  //---- COMMON ----//
  ...commonReducers,
  ...applicantReducers,
});
