import {
  Avatar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AdvertisementItem from "./AdvertisementItem";
import { connect, useDispatch } from "react-redux";

import AdvertisementQualifications from "./AdvertisementQualifications";
import AdvertisementCertificates from "./AdvertisementCertificates";
import AdvertisementCompetencies from "./AdvertisementCompetencies";
import LoginModal from "../../home/components/LoginModal";
import RegisterModal from "../../home/components/RegisterModal";
import ConfirmationDialog from "./comm/ConfirmationDialog";
import { doApply } from "../../../store/applicant/actions";
import ApplicationItem from "./ApplicationItem";
import TestComponent from "../psychometric-test";
import axios from "axios";
import AdvertisementPsychometricLanguages from "./AdvertisementPsychometricLanguages";
import AdvertisementPsychometricSubDomains from "./AdvertisementPsychometricSubDomains";
import sessionLog from "../psychometric-test/helper/ApiRequests";

function AdvertisementDetails(props) {
  const {
    user,
    loading,
    onWait,
    showDialog,
    setShowDialog,
    advertisement,
    isHome,
    userProfileStatus,
    doApply,
    env,
  } = props;

  const dispatch = useDispatch();

  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  const [educationQualified, setEducationQualified] = useState(false);
  const [certificateQualified, setCertificateQualified] = useState(false);
  const [confirmApply, setConfirmApply] = useState(false);
  const [totalScore, setTotalScore] = useState(0);
  const [error, setError] = useState("");
  const [submittingData, setSubmittingData] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState(0);
  const [totalQuestionsMarks, setTotalQuestionsMarks] = useState(0);

  const onClose = () => {
    dispatch({
      type: "SET_SELECTED_ADVERTISEMENT",
      data: null,
    });
    setShowDialog(false);
  };

  const [activeStep, setActiveStep] = useState(0);

  const [steps, setSteps] = useState([
    "Advertisement Details",
    "Psychometric Test",
  ]);

  const submitFinalTestAfterMarks = async () => {
    setSubmittingData(true);
    setTimeout(() => {
      sessionLog(
        advertisement.id,
        "SESSION ENDED",
        "THE USER FINISHED THE TEST",
        ""
      );
      setSubmittingData(false);
      window.location.href = "/applicant/applications";
    }, 1000);
  };

  useEffect(() => {
    if (isHome)
      dispatch({
        type: "SET_SELECTED_ADVERTISEMENT",
        data: advertisement,
      });
  }, []);

  return (
    <>
      <Dialog
        onClose={onClose}
        aria-labelledby="customized-dialog-title"
        open={showDialog}
        fullWidth={activeStep === 0}
        fullScreen={activeStep === 1}
        maxWidth="lg"
      >
        {activeStep === 0 ?(
          <>
            <DialogTitle id="customized-dialog-title">
              <Typography variant="overline" display="block">
                <span className="text-dark">Advertisement details {activeStep}</span>
                {(!loading || onWait) &&
                  (!educationQualified || !certificateQualified) &&
                  !isHome &&
                  !advertisement.advertisementId && (
                    <span className="badge badge-warning ml-1">
                      You are not qualified
                    </span>
                  )}

                {(!loading || onWait) &&
                  educationQualified &&
                  certificateQualified &&
                  !isHome &&
                  !advertisement.advertisementId && (
                    <span className="badge badge-success ml-1">
                      You are qualified!
                    </span>
                  )}
              </Typography>
              <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <span className="material-icons">close</span>
              </IconButton>
            </DialogTitle>
      
            
            <div className="d-none d-sm-block">
              {!isHome && !!advertisement.willHavePsychometricTest && (
                <Stepper activeStep={activeStep}>
                  {steps.map((label, index) => {
                    // const labelprops = {};
    
                    // if (isStepFailed(index)) {
                    //   labelprops.error = true;
                    // }
    
                    return (
                      <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    );
                  })}
                </Stepper>
              )}
            </div>
          </>
          ):
           <>
           <div style={{height: "100px"}}></div>
           </>
        }
        

        <DialogContent dividers className="px-1 px-sm-3">
          <div
            style={{
              minHeight: "73vh",
            }}
          >
            {activeStep === 0 && (
              <div className="card rounded">
                <div
                  className="card-header rounded-top p-0"
                  style={{ padding: "0.25rem 1.2rem" }}
                >
                  {!advertisement.advertisementId ? (
                    <AdvertisementItem
                      roundedValue="rounded-top"
                      advertisement={advertisement}
                      isForView={true}
                      totalQuestions={totalQuestions}
                    />
                  ) : (
                    <ApplicationItem
                      roundedValue="rounded-top"
                      application={advertisement}
                      isForView={true}
                      totalQuestions={totalQuestions}
                    />
                  )}
                </div>

                <div className="card-body">
                  <div className="row mt-n3">
                    <div className="col-12 col-md-6 text-left ">
                      <small className="">
                        <strong style={{ textDecoration: "underline" }}>
                          Exams to be conducted
                        </strong>{" "}
                        <br />
                        {advertisement.willHavePsychometricTest && (
                          <>
                            <span>1:PsychometricTest</span>
                            <br />
                          </>
                        )}
                        {advertisement.willHaveWrittenExam && (
                          <>
                            <span>
                              {advertisement.willHavePsychometricTest
                                ? "2"
                                : "1"}
                              :Written
                            </span>
                            <br />
                          </>
                        )}
                        {advertisement.willHaveOralExam && (
                          <span>
                            {advertisement.willHavePsychometricTest &&
                            advertisement.willHaveWrittenExam
                              ? "3"
                              : advertisement.willHaveWrittenExam
                              ? "2"
                              : "1"}
                            :Oral
                          </span>
                        )}
                      </small>
                    </div>
                    <div className="col-12 col-md-6 text-right">
                      <strong style={{ textDecoration: "underline" }}>
                        Reports To
                      </strong>{" "}
                      <br />
                      <span className="text-primary">
                        {advertisement.reportsTo}
                      </span>
                    </div>
                  </div>
                  {/* Job description */}
                  <div className=" mt-2">
                    <strong>Job responsibilities</strong>
                    <div
                      className="mt-1 p-4 rounded"
                      contentEditable={false} // Set to true if you want it editable
                      style={{
                        whiteSpace: "pre-line",
                        backgroundColor: "#f9f9f9",
                        borderRadius: "8px",
                        border: `1px solid #c8c6c6`,
                      }}
                    >
                      {advertisement.jobDescriptions}
                    </div>
                  </div>

                  <AdvertisementQualifications
                    advertisement={advertisement}
                    isHome={isHome}
                    educationQualified={educationQualified}
                    setEducationQualified={setEducationQualified}
                  />

                  <AdvertisementCertificates
                    advertisement={advertisement}
                    isHome={isHome}
                    certificateQualified={certificateQualified}
                    setCertificateQualified={setCertificateQualified}
                  />

                  <AdvertisementCompetencies advertisement={advertisement} />

                  <AdvertisementPsychometricLanguages
                    requisitionId={advertisement.requisitionId}
                  />
                  <AdvertisementPsychometricSubDomains
                    requisitionId={advertisement.requisitionId}
                  />
                </div>
              </div>
            )}

            {activeStep === 1 && (
              <TestComponent
                totalScore={totalScore}
                setTotalScore={setTotalScore}
                onSubmitTest={(totalQ) => {
                  console.log("totalQ: ", totalQ);
                  setTotalQuestions(totalQ);
                  if (isHome) setShowLoginDialog(true);
                  else setConfirmApply(true);
                }}
                onCancel={() => setActiveStep(0)}
                advertisement={advertisement}
                setTotalQuestionsMarks={setTotalQuestionsMarks}
              />
            )}
          </div>
        </DialogContent>
        <DialogActions className="pr-4 py-3">
          {!isHome && !advertisement.advertisementId && activeStep !== 1 && (
            <div
              className="d-flex align-items-center justify-content-between w-100"
              style={{ display: "none" }}
            >
              <Button
                variant="contained"
                color="inherit"
                onClick={() => activeStep < 1 && setActiveStep(activeStep - 1)}
                disabled={activeStep === 0}
              >
                Prev
              </Button>
              {steps.length - 1 > activeStep && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setActiveStep(activeStep + 1)}
                  // disabled={
                  //   steps.length - 1 === activeStep ||
                  //   confirmApply ||
                  //   showLoginDialog ||
                  //   showRegisterDialog ||
                  //   advertisement.wasApplied ||
                  //   ((!educationQualified || !certificateQualified) && !isHome)
                  // }
                >
                  Next
                </Button>
              )}
              {activeStep === 1 && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure do you want to submit the test?"
                      ) === true
                    ) {
                      if (isHome) setShowLoginDialog(true);
                      else setConfirmApply(true);
                    }
                  }}
                >
                  Submit
                </Button>
              )}
            </div>
          )}

          {isHome && (
            <Button
              disabled={
                loading ||
                confirmApply ||
                showLoginDialog ||
                showRegisterDialog ||
                advertisement.wasApplied ||
                ((!educationQualified || !certificateQualified) && !isHome)
              }
              onClick={() => {
                if (isHome) setShowLoginDialog(true);
                else setConfirmApply(true);
              }}
              variant="contained"
              type="button"
            >
              Apply now
            </Button>
          )}
        </DialogActions>
      </Dialog>

      {confirmApply && totalQuestions > 0 && (
        <ConfirmationDialog
          disabled={!educationQualified || !certificateQualified}
          btnMessage={
            submittingData === true
              ? "Please wait..."
              : (totalScore * 100) / totalQuestionsMarks < 50
              ? "Close the test"
              : "Proceed to the application"
          }
          confirmationDialog={confirmApply}
          message={
            (totalScore * 100) / totalQuestionsMarks >= 50
              ? `PSYCHOMETRIC TEST SUBMITTED SUCCESSFULLY ${totalScore} of ${totalQuestionsMarks}`
              : `YOU FAILED THE PSYCHOMETRIC TEST ${totalScore} of ${totalQuestionsMarks}`
          }
          totalScore={(parseFloat(totalScore) * 100) / totalQuestionsMarks}
          setConfirmationDialog={setConfirmApply}
          onYes={() => {
            if (submittingData === false) {
              doApply(
                {
                  advertisementId: advertisement.id,
                  requisitionId: advertisement.requisitionId,
                },
                () => {
                  setConfirmApply(false);
                  setShowDialog(false);
                  dispatch({
                    type: "SET_SELECTED_ADVERTISEMENT",
                    data: null,
                  });
                }
              );
              submitFinalTestAfterMarks();
            }
          }}
        ></ConfirmationDialog>
      )}

      {showLoginDialog && (
        <LoginModal
          showDialog={showLoginDialog}
          setShowDialog={setShowLoginDialog}
          showRegisterDialog={showRegisterDialog}
          setShowRegisterDialog={setShowRegisterDialog}
        />
      )}
      {showRegisterDialog && (
        <RegisterModal
          showDialog={showRegisterDialog}
          setShowDialog={setShowRegisterDialog}
          showLoginDialog={showLoginDialog}
          setShowLoginDialog={setShowLoginDialog}
        />
      )}
    </>
  );
}

const mapStateToProps = ({
  user,
  env,
  loading,
  onWait,
  userProfileStatus,
}) => ({
  user,
  env,
  loading,
  onWait,
  userProfileStatus,
});

export default connect(mapStateToProps, { doApply })(AdvertisementDetails);
