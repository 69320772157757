import React, { useState, useEffect } from "react";
import { Box, Typography, Paper } from "@mui/material";
import { useTranslation } from "react-i18next";

const ProctoringCountDown = ({ onCompleted }) => {
  const [count, setCount] = useState(15); // Initial countdown value
  const { t } = useTranslation();

  useEffect(() => {
    if (count > 0) {
      const timer = setTimeout(() => setCount(count - 1), 1000); // Decrease count every second
      return () => clearTimeout(timer); // Cleanup the timer
    } else {
      // Countdown completed, trigger the onCompleted callback
      onCompleted();
    }
  }, [count]); // Re-run effect whenever count changes

  return (
    <div>
      <div
        style={{
          padding: "2rem",
          borderRadius: "20px",
          textAlign: "center",
          minWidth: "300px",
          //   background: "#f5f5f5",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            color: "#1976d2",
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyItems: "center",
            textAlign: "center",
          }} // Primary color
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "280px",
                width: "280px",
                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bolder",
                textAlign: "center",
              }}
              className="bg-primary"
            >
              <div>
                <div
                  style={{
                    fontSize: "100px",
                    marginTop: "-6px",
                    fontWeight: "bolder",
                  }}
                >
                  {count}
                </div>
                <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                  {count > 0 ? <div>{t("getReady")}</div> : t("starting")}
                </div>
              </div>
            </div>
          </div>
        </Typography>
        {/* <Typography variant="body1" sx={{ marginTop: "1rem" }}>
          {count > 0 ? "Please wait for the countdown to finish." : ""}
        </Typography> */}
      </div>
    </div>
  );
};

export default ProctoringCountDown;
