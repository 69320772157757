import React, { useState, useEffect } from "react";
const OfflineMessage = () => {
  let [online, isOnline] = useState(navigator.onLine);

  const setOnline = () => {
    isOnline(true);
  };
  const setOffline = () => {
    isOnline(false);
  };

  useEffect(() => {
    window.addEventListener("offline", setOffline);
    window.addEventListener("online", setOnline);

    return () => {
      window.removeEventListener("offline", setOffline);
      window.removeEventListener("online", setOnline);
    };
  }, []);

  return (
    <>
      {!online && (
        <div
          className="bg-danger text-white text-center px-1 py-0 "
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100vw",
            zIndex: "99999",
            fontSize: "10px",
          }}
        >
          Offline
        </div>
      )}
    </>
  );
};

export default OfflineMessage;
