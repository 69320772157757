import React from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { t } from "i18next";

const CandidateLocked = ({ submittingTest }) => {
  return (
    <Dialog
      open={true}
      onClose={() => {}}
      PaperProps={{
        style: {
          borderRadius: "15px",
          background: "darkred",
          color: "white",
          padding: "16px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }, // Add border-radius here
        width: "1000px", // Set custom width (adjust as needed)
        maxWidth: "none", // Prevent predefined maxWidth from limiting the width
      }}
      style={{ background: "black" }}
      BackdropProps={{
        onClick: (e) => e.stopPropagation(), // Prevent close on backdrop click,
        style: {
          backgroundColor: "rgba(0, 0, 0, 0.6)", // Add blur effect to background
          backdropFilter: "blur(8px)",
        },
      }}
    >
      <DialogContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <div>
          <DangerousIcon style={{ color: "orange", fontSize: "200px" }} />
        </div>
        <div>
          <span
            style={{
              background: "red",
              color: "white",
              fontSize: "14px",
              borderRadius: "10px",
              padding: "2px 10px",
              fontWeight: "bold",
            }}
          >
            {t("accountLocked")}
          </span>
        </div>
        <div style={{ fontSize: "28px", fontWeight: "bold" }}>
         {t("examTerminated")}
        </div>
        <div style={{ paddingTop: "10px" }}>
          {t("sessionTerminated")}
        </div>
        {submittingTest === true && (
          <div
            className=""
            style={{ color: "orange", fontWeight: "bold", marginTop: "10px" }}
          >
            {t("loadingWait")}
          </div>
        )}
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => {
            window.location.href = "/";
          }}
          style={{
            background: "orange",
            color: "white",
            borderRadius: "20px",
            paddingLeft: "20px",
            paddingRight: "20px",
          }}
        >
          {t("clickToContinue")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CandidateLocked;
