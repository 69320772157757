import React, { Fragment, useState, useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import "@tensorflow/tfjs";
import axios from "axios";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { CheckCircle, Error, CameraAltOutlined } from "@mui/icons-material";
import Detection from "../Detections/Detections";
import "./ProctoringContainer.css";
import FullScreenContainer from "../components/fullScreenContainer/FullScreenContainer";
import sessionLog from "../helper/ApiRequests";
import { checkCameraPermissions } from "../helper/checkCameraPermission";
import { useTranslation } from "react-i18next";
import TranslateIcon from "@mui/icons-material/Translate";
import DesktopAccessDisabledIcon from "@mui/icons-material/DesktopAccessDisabled";
import LanguageStep from "../components/ProctoringSteps/LanguageStep";
import ExamRules from "../components/ProctoringSteps/ExamRules";
import Permissions from "../components/ProctoringSteps/Permissions";
import FaceDetection from "../components/ProctoringSteps/FaceDetection";
import ProctoringCountDown from "../components/ProctoringSteps/ProctoringCountDown";
import CandidateLocked from "../components/CandidateLocked/CandidateLocked";
import AlertMessage from "../components/AlertMessage/AlertMessage";
import ProgressBar from "../components/ProgressBar/ProgressBar";
import VoiceActivityDetection from "../components/voiceDetection/VoiceActivityDetection";

const initialSteps = [
  {
    order: 1,
    label: "language",
    icon: TranslateIcon,
    isDone: false,
    isCurrent: true,
  },
  {
    order: 2,
    label: "examRules",
    icon: Error,
    isDone: false,
    isCurrent: false,
  },
  {
    order: 3,
    label: "permissions",
    icon: DesktopAccessDisabledIcon,
    isDone: false,
    isCurrent: false,
  },
  {
    order: 4,
    label: "detectFace",
    icon: CameraAltOutlined,
    isDone: false,
    isCurrent: false,
  },
];

export default function ProctoringContainer({
  children,
  onCancel,
  handleSetLanguage,
  handleLanguageChange,
  selectedLanguage,
  advertisement,
  existingTestData,
  setExistingTestData,
  isTestLocked,
  setIsTestLocked,
  istTestSubmitted,
  isTestStarted,
  setIsTestStarted,
}) {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const { t } = useTranslation();

  const [errorCounts, setErrorCounts] = useState({
    multipleFaces: 0,
    cellPhone: 0,
    book: 0,
    laptop: 0,
    faceNotVisible: 0,
    others: 0,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [instructionsOpen, setInstructionsOpen] = useState(true);
  const [permissionErrors, setPermissionErrors] = useState("");
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [imageSrc, setImageSrc] = useState(null);
  const [loading, setLoading] = useState(false);
  const [examLanguages, setExamLanguages] = useState([]);
  const [submittingTest, setSubmittingTest] = useState(false);
  const [isBrowserAllowed, setIsBrowserAllowed] = useState(null);
  const [isCameraAllowed, setIsCameraAllowed] = useState(null);
  const [allPermitted, setAllPermitted] = useState(false);
  const [steps, setSteps] = useState(initialSteps);
  const [isOperatingSystemAllowed, setIsOperatingSystemAllowed] =
    useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [confirmStartTest, setConfirmStartTest] = useState(false);
  const [warningDetection, setWarningDetection] = useState(null);
  const [activityMonitorError, setActivityMonitorError] = useState({
    title: null,
    message: null,
    active: false,
  });
  const [maxNoiseVolume, setMaxNoiseVolume] = useState(1);
  const [sessionStatus, setSessionStatus] = useState(false);

  useEffect(() => {
    const requisitionId = advertisement.id.slice(0, -1);
    if (requisitionId) {
      setLoading(true);
      setSubmittingTest(true);
      axios
        .get(`/api/psychometric-test/gettestlanguages/${requisitionId}`)
        .then((response) => {
          if (response.data) {
            setExamLanguages(response.data);
            //
            if (response.data.length === 1) {
              const lang = response.data[0].name;
              setExamLanguages(lang);
              // handleLanguageChange(lang, (loadingTest) => {
              //   setLoading(loadingTest);
              // });
            }
          }
          setLoading(false);
          setSubmittingTest(false);
        })
        .catch((error) => {
          setSubmittingTest(false);
          console.error("Error fetching test languages:", error);
          setLoading(false);
        });
    }
  }, [advertisement]);

  /**
   * Takes a screenshot and logs the session with the image.
   *
   * @param {string} advertisement_id - The ID of the advertisement.
   * @param {string} action - The type of action (e.g., "WARNING", "SESSION START", "SESSION END").
   * @param {string} message - Additional information for the action.
   */
  const takeScreenShotHandler = (advertisement_id, action, message) => {
    setLoading(true);
    // Get the element by its ID that we want to take a screenshot of
    const element = document.getElementById("divToTakeScreenShotOf");
    // Check if the element exists
    if (!element) {
      console.log("element not found!");
      setLoading(false);
      // console.log("element not found!");
      return;
    }
    // Use html2canvas to capture the screenshot of the element
    html2canvas(element)
      .then(function (canvas) {
        // Convert the captured canvas to a PNG image data base64
        const image = canvas.toDataURL("image/png");
        // Log the session with the image to database
        sessionLog(advertisement_id, action, message, image.split(",")[1]);
        setLoading(false);

        //!Optional: Uncomment the following lines if you want to download the screenshot
        // console.log("the image is:", image);
        // const link = document.createElement("a");
        // link.href = image;
        // link.download = "screenshot.png";
        // link.click();
      })
      .catch((err) => {
        setLoading(false);
        // console.log(
        //   " we can not take screenshot of your element at the moment! => ",
        //   err
        // );
        return;
      });
  };
  const handleStepClick = (order) => {
    const allPreviousStepsDone = steps
      .filter((step) => step.order < order)
      .every((step) => step.isDone);

    const isCurrent = steps.find((step) => step.isCurrent);
    if (isCurrent && isCurrent.order === order) return;

    if (allPreviousStepsDone) {
      setCurrentStep(order);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.order === order
            ? { ...step, isCurrent: true }
            : { ...step, isCurrent: false }
        )
      );
    }
  };

  const handleBackClick = () => {
    setErrorMessage(null);
    const Step = steps.find((step) => step.isCurrent);
    if (Step.order > 1) {
      setCurrentStep(Step.order - 1);
      setSteps((prevSteps) =>
        prevSteps.map((step) =>
          step.order === Step.order - 1
            ? { ...step, isCurrent: true }
            : { ...step, isCurrent: false }
        )
      );
    }

    if (Step.order === 1) {
      onCancel();
    }
  };

  const handleCompleteStep = () => {
    setErrorMessage(null);
    setLoading(false);
    const Step = steps.find((step) => step.isCurrent);

    if (Step.order === 1) {
      if (selectedLanguage == null) {
        setErrorMessage({
          title: "Language Error",
          description: "Please select a language",
        });
        return;
      } else {
        setCurrentStep(2);
        setSteps((prevSteps) => {
          return prevSteps.map((step) =>
            step.order === 1
              ? { ...step, isCurrent: false, isDone: true }
              : step.order === 2
              ? { ...step, isCurrent: true }
              : step
          );
        });
      }
    }
    if (Step.order === 2) {
      setCurrentStep(3);
      setSteps((prevSteps) => {
        return prevSteps.map((step) =>
          step.order === 2
            ? { ...step, isCurrent: false, isDone: true }
            : step.order === 3
            ? { ...step, isCurrent: true }
            : step
        );
      });
    }
    if (Step.order === 3) {
      setImageSrc(null);
      // Add conditions here
      if (!allPermitted) {
        setErrorMessage({
          title: t("noPermissionsT"),
          description: permissionErrors || t("noPermissionsT"),
        });
        return;
      }
      setCurrentStep(4);
      setSteps((prevSteps) => {
        return prevSteps.map((step) =>
          step.order === 3
            ? { ...step, isCurrent: false, isDone: true }
            : step.order === 4
            ? { ...step, isCurrent: true }
            : step
        );
      });
    }
    if (Step.order === 4) {
      if (imageSrc !== null) {
        if (confirmStartTest !== true) {
          setConfirmStartTest(true);
          setIsTestStarted(true);
        } else {
          setSteps((prevSteps) => {
            return prevSteps.map((step) =>
              step.order === 4
                ? { ...step, isCurrent: false, isDone: true }
                : step.order === 5
                ? { ...step, isCurrent: true }
                : step
            );
          });


          const base64Image = imageSrc === null ? "" : imageSrc.split(",")[1]; // Clean Base64
          
          handleLanguageChange(selectedLanguage,base64Image,
            (loadingTest) => {
              setLoading(loadingTest);
            }
          );
          
          setCurrentStep(5);
          setConfirmStartTest(false);
          
          setTimeout(() => {
            setWarningDetection(null);
          }, 1000);
        }
      } else {
        setErrorMessage({
          title: t("noCameraT"),
          description: t("noCameraD"),
        });
      }
    }
  };

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const checkForExistingTest = async () => {
    setLoading(true);
    setSubmittingTest(true);
    try {
      const { data: response } = await axios.get(
        `/api/psychometric-test/checkforexistingsession/${advertisement.id}`
      );
      // Check if the response array exists and has at least one element
      if (
        !response ||
        response.length === 0 ||
        !response[0].preferredLanguageId
      ) {

        setLoading(false);
      } else {
        setSessionStatus(true)
        console.log({"existing sesssion:": response})
        // Get the preferred language ID and convert it to a string
        const languageId = response[0].preferredLanguageId.toString();
        setExistingTestData(response);
        if (response.length > 0) {
          handleSetLanguage(
            languageId.toString() === "3"
              ? "Kinyarwanda"
              : languageId.toString() === "2"
              ? "Francais"
              : languageId.toString() === "1"
              ? "English"
              : "Kinyarwanda",
            (loadingTest) => {
              setLoading(loadingTest);
            }
          );
        }
        setLoading(false);
      }
      setSubmittingTest(false);
    } catch (err) {
      setExistingTestData([]);
      console.log(err);
      setLoading(false);
      setSubmittingTest(false);
    }
  };

  const disableCopyCutPaste = () => {
    const handlePrevent = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to document
    document.addEventListener("copy", handlePrevent);
    document.addEventListener("cut", handlePrevent);
    document.addEventListener("paste", handlePrevent);

    // Cleanup event listeners when component unmounts
    return () => {
      document.removeEventListener("copy", handlePrevent);
      document.removeEventListener("cut", handlePrevent);
      document.removeEventListener("paste", handlePrevent);
    };
  };

  // Count total number of warnings
  const totalWarnings =
    errorCounts.multipleFaces +
    errorCounts.book +
    errorCounts.cellPhone +
    errorCounts.faceNotVisible +
    errorCounts.laptop +
    errorCounts.others;

  // Lock a candidate if the number of warnings is greater than 4
  const lockTheCandidate = async () => {
    setLoading(true);
    setSubmittingTest(true);
    const res = await axios.post(`/api/psychometric-test/lockthecandidate`, {
      advertisementId: advertisement.id,
    });
    if (res) {
      setIsTestLocked(true);
      setLoading(false);
      setSubmittingTest(false);
    }
    setLoading(false);
    setSubmittingTest(false);
    setWarningDetection(null);
  };

  useEffect(() => {
    disableCopyCutPaste();
    existingTestData === null && checkForExistingTest();
  }, [existingTestData]);

  // Periodically check camera permissions
  useEffect(() => {
    const checkStatus = async () => {
      const hasPermission = await checkCameraPermissions();
      setIsCameraAllowed(hasPermission);
    };

    // Check initially
    checkStatus();

    // Check every minute
    const interval = setInterval(checkStatus, 10000); // 60,000 ms = 1 minute

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // When max noise volume changed check if is above the allowed limit and then give warning or error
  useEffect(() => {
    if (maxNoiseVolume > 40) {
      setActivityMonitorError({
        title: "Unacceptable noise detected",
        message: "The system has detect unacceptable noise",
        active: true,
      });
      setMaxNoiseVolume(1);
    }
  }, [maxNoiseVolume]);

  // // Activity Monitor
  // useEffect(() => {
  //   // Tab switch or window minimize detection
  //   const handleVisibilityChange = () => {
  //     if (document.hidden) {
  //       setActivityMonitorError({
  //         title: t("windowMinT"),
  //         message: t("windowMinD"),
  //         active: true,
  //       });
  //     }
  //   };

  //   // Mouse leaving the screen detection
  //   const handleMouseLeave = (event) => {
  //     if (event.clientY <= 0) {
  //       // Mouse left the browser at the top
  //       setActivityMonitorError({
  //         title: t("mouseLeftBrowserTitle"),
  //         message: t("mouseLeftBrowserMessage"),
  //         active: true,
  //       });
  //     } else if (event.clientX <= 0) {
  //       // Mouse left the browser on the left side
  //       setActivityMonitorError({
  //         title: t("mouseLeftBrowserTitle"),
  //         message: t("mouseLeftBrowserMessage"),
  //         active: true,
  //       });
  //     } else if (event.clientX >= window.innerWidth) {
  //       // Mouse left the browser on the right side
  //       setActivityMonitorError({
  //         title: t("mouseLeftBrowserTitle"),
  //         message: t("mouseLeftBrowserMessage"),
  //         active: true,
  //       });
  //     } else if (event.clientY >= window.innerHeight) {
  //       // Mouse left the browser at the bottom
  //       setActivityMonitorError({
  //         title: t("mouseLeftBrowserTitle"),
  //         message: t("mouseLeftBrowserMessage"),
  //         active: true,
  //       });
  //     }
  //   };

  //   // Add event listeners
  //   document.addEventListener("visibilitychange", handleVisibilityChange);
  //   document.addEventListener("mouseout", handleMouseLeave);

  //   // Cleanup on unmount
  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //     document.removeEventListener("mouseout", handleMouseLeave);
  //   };
  // }, []);

  return (
    <Fragment>
      {/* WebCam and Detection Component Display */}
      <div
        style={{
          position: "fixed",
          // top: 10,
          // right: 10,
          zIndex: 9999999,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleOpenDialog}
          style={{
            backgroundColor: "#f0f0f0",
            color: "#000",
            display: "none",
          }}
        >
          Show Monitored Actions
        </Button>
      </div>

      <Dialog
        open={instructionsOpen}
        sx={{
          "& .MuiPaper-root": {
            borderRadius: "24px",
            border: 1,
            borderColor: "#dcf1ff",
            width: "90vw",
            height: "90vh",
            margin: 0,
            maxWidth: "none",
          },
        }}
      >
        <DialogTitle
          style={{
            fontSize: "15px",
            fontWeight: "normal",
            display: "flex",
            alignItems: "center",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "32px",
              fontWeight: "bold",
              color: "#1a76d2",
            }}
          >
            Psychometric Test
          </div>
          <div style={{ ml: 2 }}>
            <div
              style={{
                fontWeight: "bold",
                fontSize: "20px",
                fontWeight: "bold",
                color: "#1a76d2",
              }}
            >
              {advertisement.positionName}
            </div>
            <div
              style={{
                fontSize: "13px",
                marginTop: "-4px",
                marginBottom: "15px",
              }}
            >
              {advertisement.entityName}
            </div>
          </div>
        </DialogTitle>
        <div
          style={{
            backgroundColor: "#dcf1ff",
            fontSize: "15px",
            fontWeight: "normal",
            width: "100%",
            height: "10vh",
            padding: "4px 3%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              color: "000",
              height: "100%",
              paddingTop: "12px",
            }}
          >
            {steps.map((step, index) => (
              <div
                key={index}
                onClick={() => handleStepClick(step.order)}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                {step.isDone ? (
                  <CheckCircle color="success" style={{ fontSize: "28px" }} />
                ) : (
                  <step.icon
                    style={{
                      fontSize: "28px",
                      opacity: step.isCurrent ? "100%" : "40%",
                      color: "#1a76d2",
                    }}
                  />
                )}
                <p
                  style={{
                    fontWeight: step.isCurrent ? "bold" : "normal",
                    color: "#000",
                    fontSize: "12px",
                  }}
                >
                  {t(`${step.label}`)}
                </p>
              </div>
            ))}
          </div>
        </div>
        <DialogContent>
          {errorMessage !== null && errorMessage.title && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                backdropFilter: "blur(5px)",
                zIndex: 1299,
              }}
              onClick={() => setErrorMessage(null)}
            >
              <div
                style={{
                  position: "fixed",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  zIndex: 1300,
                  background: "transparent",
                  border: "none",
                }}
              >
                <AlertMessage
                  type={"error"}
                  errorMessageTitle={errorMessage.title}
                  errorMessageDescription={errorMessage.description}
                  onClose={() => setErrorMessage(null)}
                />
              </div>
            </div>
          )}
          {loading === true ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            <>
              {currentStep === 1 && (
                <LanguageStep
                  handleSetLanguage={handleSetLanguage}
                  //handleLanguageChange={handleLanguageChange}
                  examLanguages={examLanguages}
                  selectedLanguage={selectedLanguage}
                  t={t}
                  isLoading={loading}
                  sessionExists = {sessionStatus}
                />
              )}
              {currentStep === 2 && <ExamRules />}
              {currentStep === 3 && (
                <Permissions
                  setAllPermitted={(value) => setAllPermitted(value)}
                  setPermissionErrors={(errors) => setPermissionErrors(errors)}
                />
              )}
              {currentStep === 4 && (
                <FaceDetection
                  imageSrc={imageSrc}
                  setImageSrc={setImageSrc}
                  step={currentStep}
                  callBack={() => {}}
                  setIsOperatingSystemAllowed={setIsOperatingSystemAllowed}
                  confirmStartTest={confirmStartTest}
                  setConfirmStartTest={(status) => {
                    setConfirmStartTest(status);
                    if (status === false) {
                      setCurrentStep(4);
                    } else {
                      handleCompleteStep();
                    }
                  }}
                />
              )}
              {/* CountDown */}
              {currentStep === 5 && (
                <ProctoringCountDown
                  onCompleted={() => setInstructionsOpen(false)}
                />
              )}
            </>
          )}
        </DialogContent>
        {loading === false && currentStep !== 5 && (
          <DialogActions
            sx={{
              justifyContent: "space-between",
              width: "100%",
              paddingBottom: "20px",
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "8px",
              }}
            >
              <Button
                onClick={handleBackClick}
                variant="contained"
                sx={{
                  backgroundColor: "#f5f5f5",
                  color: "#000",
                  borderRadius: "50px",
                  textAlign: "center",
                  width: "180px",
                  height: "40px",
                  boxShadow: "none",
                  "&:hover": {
                    backgroundColor: "#e0e0e0",
                    boxShadow: "none",
                  },
                }}
              >
                {t("back")}
              </Button>
              <Button
                onClick={handleCompleteStep}
                color="primary"
                variant="contained"
                sx={{
                  borderRadius: "50px",
                  textAlign: "center",
                  minWidth: "180px",
                  padding: "4px 14px",
                  height: "40px",
                  boxShadow: "none",
                }}
              >
                {currentStep < 4 ? (
                  <span>{t("continue")}</span>
                ) : (
                  <span>{t("acceptAndContinue")}</span>
                )}
              </Button>
            </div>
          </DialogActions>
        )}
      </Dialog>

      {/* Monitored actions dialog */}
      <Dialog open={false} onClose={handleCloseDialog}>
        <DialogTitle>Monitored Actions and Errors</DialogTitle>
        <DialogContent>
          <ul>
            <li>Multiple Faces Detected: {errorCounts.multipleFaces}</li>
            <li>Cell Phone Detected: {errorCounts.cellPhone}</li>
            <li>Book Detected: {errorCounts.book}</li>
            <li>Laptop Detected: {errorCounts.laptop}</li>
            <li>Face Not Visible: {errorCounts.faceNotVisible}</li>
          </ul>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Check if candidate is clocked */}
      {isTestLocked === true && (
        <CandidateLocked submittingTest={submittingTest} />
      )}

      {/* Check for the browser: Chrome, Firefox, or Safari */}
      {isBrowserAllowed !== null && (
        <Dialog
          open={!isBrowserAllowed}
          onClose={() => setIsBrowserAllowed(false)}
        >
          <DialogTitle>Warning: Invalid Browser</DialogTitle>
          <DialogContent>
            <div>
              <div style={{ marginBottom: "10px" }}>
                List of allowed browsers:
              </div>
              <ul>
                <li>Chrome</li>
                <li>Firefox</li>
                <li>Safari</li>
              </ul>
            </div>
            <div
              style={{ color: "orange", fontWeight: "bold", marginTop: "10px" }}
            >
              Please close and change the browser to one of the above.
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                window.location.href = "/";
              }}
              color="primary"
            >
              Click to go back
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* Check if camera allowed */}
      {isCameraAllowed !== null && currentStep > 4 && (
        <Dialog
          open={!isCameraAllowed}
          onClose={() => setIsCameraAllowed(false)}
        >
          <DialogTitle>{t("cameraWtitle")}</DialogTitle>
          <DialogContent>
            <div
              style={{ color: "orange", fontWeight: "bold", marginTop: "10px" }}
            >
              {t("cameraWmessage")}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                window.location.href = "/";
              }}
              color="primary"
            >
              {t("back")}
            </Button>
          </DialogActions>
        </Dialog>
      )}

      {/* activity monitor error message */}
      {activityMonitorError.active && currentStep > 4 && (
        <Dialog
          open={activityMonitorError.active}
          onClose={() =>
            setActivityMonitorError({
              title: null,
              message: null,
              active: false,
            })
          }
        >
          <DialogTitle>Warning: {activityMonitorError.title}</DialogTitle>
          <DialogContent>
            <div
              style={{ color: "orange", fontWeight: "bold", marginTop: "10px" }}
            >
              {activityMonitorError.message}
            </div>
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() =>
                setActivityMonitorError({
                  title: null,
                  message: null,
                  active: false,
                })
              }
              color="primary"
            >
              Click to go back
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <div>
        {/* Displaying video */}
        {selectedLanguage !== null &&
          instructionsOpen === false &&
          isTestLocked === false &&
          istTestSubmitted === false &&
          totalWarnings <= 3 && (
            <div
              style={{
                position: "fixed",
                opacity: 9999999,
                right: 210,
                bottom: 240,
                zIndex: 9999999,
              }}
            >
              <div>
                <VoiceActivityDetection setMaxNoiseVolume={setMaxNoiseVolume} />
              </div>
              <header id="divToTakeScreenShotOf">
                <Detection
                  errorCounts={errorCounts}
                  setErrorCounts={(value, message) => {
                    if (message !== "") {
                      setErrorCounts(value);
                      setDialogOpen(true);
                      const base64Image =
                        imageSrc === null ? "" : imageSrc.split(",")[1]; // Clean Base64
                      takeScreenShotHandler(
                        advertisement.id,
                        "WARNING",
                        message
                      );
                      // Submit test after 3 warnings
                      if (totalWarnings >= 3) {
                        lockTheCandidate();
                        //alert("The system detected multiple violations (e.g., another person present, smartphone usage, or talking). After the 3rd warning, the exam has been ended.");
                      }
                    }
                  }}
                ></Detection>
              </header>
            </div>
          )}

        {instructionsOpen === false &&
          isTestLocked === false &&
          totalWarnings <= 4 && (
            <FullScreenContainer
              isFullScreen={isFullScreen}
              setIsFullScreen={setIsFullScreen}
              advertisement={advertisement}
              currentStep={currentStep}
              warningDetection={warningDetection}
              onSetWarningDetection={(warningDetected) => {
                if (isTestLocked === true || totalWarnings >= 4) {
                  setWarningDetection(null);
                } else {
                  if (loading === false) {
                    warningDetected !== null &&
                      setErrorCounts((prev) => ({
                        ...prev,
                        others: prev.others + 1,
                      }));
                    setWarningDetection(warningDetected);
                    // Take a screenshot when a warning is detected
                    warningDetected !== null &&
                      takeScreenShotHandler(
                        advertisement.id,
                        warningDetected.type,
                        warningDetected.message
                      );
                  }
                }
                // Submit test after 3 warnings
                if (isTestLocked === false && totalWarnings >= 4) {
                  lockTheCandidate();
                  //alert("The system detected multiple violations (e.g., another person present, smartphone usage, or talking). After the 3rd warning, the exam has been ended.");
                }
              }}
            >
              {/* The exam component */}
              {submittingTest === true ? (
                <div
                  className="p-3"
                  style={{ padding: "20px", textAlign: "center" }}
                >
                  Submitting test...
                </div>
              ) : (
                selectedLanguage !== null &&
                instructionsOpen === false &&
                isTestLocked === false &&
                totalWarnings <= 3 && (
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      marginBottom: "-50px",
                      filter: instructionsOpen ? "blur(5px)" : "none",
                    }}
                  >
                    <ProgressBar totalWarnings={totalWarnings} />
                    <div>{children}</div>
                  </div>
                )
              )}
            </FullScreenContainer>
          )}
      </div>
    </Fragment>
  );
}
