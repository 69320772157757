import DetectRTC from "detectrtc";

// Function to check camera permissions
export const checkCameraPermissions = async () => {
  return new Promise((resolve) => {
    DetectRTC.load(() => {
      if (DetectRTC.isWebsiteHasWebcamPermissions) {
        resolve(true);
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then((stream) => {
            stream.getTracks().forEach((track) => track.stop()); // Stop the stream
            resolve(true);
          })
          .catch(() => resolve(false));
      }
    });
  });
};
