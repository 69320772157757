import axios from "../utils/axios-middleware";
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import { showError } from "../toastify";
import loadingGif from "../assets/loading.gif";
import {
  Alert,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Typography,
} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import UnpublishedRoundedIcon from "@mui/icons-material/UnpublishedRounded";

import { logout } from "../../store/comm/actions";

const atLeastOneUppercase = /[A-Z]/g; // capital letters from A to Z
const atLeastOneLowercase = /[a-z]/g; // small letters from a to z
const atLeastOneNumeric = /[0-9]/g; // numbers from 0 to 9
const atLeastOneSpecialChar = /[#?!@$%^&*-]/g; // any of the special characters within the square brackets
const eightCharsOrMore = /.{8,}/g; // eight characters or more

const ResetPassword = (props) => {
  const { logout } = props;

  const [loading, setLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [successMessage, setSuccessMessage] = useState("");
  const [error, setError] = useState("");
  const [credentials, setCredentials] = useState({
    password: "",
    confirmPassword: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const location = useLocation();
  const history = useHistory();
  const queryParameters = new URLSearchParams(location.search);
  const token = queryParameters.get("token");

  useEffect(() => {
    logout(history, location.pathname);
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    confirmResetPassword();
  };

  const checkToken = async (token) => {
    setError("");
    setIsFetching(true);

    try {
      await axios.get("/api/auth/verify-password-reset-token/" + token);

      setIsFetching(false);
    } catch (error) {
      setIsFetching(false);

      if (error && error.response && error.response.data) {
        if (error.response.data.toString().includes("<html"))
          return setError("Invalid password resset token");
        else return setError(error.response.data);
      } else if (error.message) return setError(error.message);
      else return setError("Invalid password resset token");
    }
  };

  useEffect(() => {
    if (!!token) checkToken(token);
    else history.push("/");
  }, [token]);

  const confirmResetPassword = async () => {
    setLoading(true);

    try {
      const { data } = await axios.post("/api/auth/confirm-reset-password", {
        token: token,
        password: credentials.password,
        confirmPassword: credentials.confirmPassword,
      });

      setError("");
      setSuccessMessage(data.message);
      setLoading(false);
      //history.push("/");
    } catch (error) {
      setLoading(false);
      showError(error);
    }
  };

  const passwordTracker = {
    uppercase: credentials.password.match(atLeastOneUppercase),
    lowercase: credentials.password.match(atLeastOneLowercase),
    number: credentials.password.match(atLeastOneNumeric),
    specialChar: credentials.password.match(atLeastOneSpecialChar),
    eightCharsOrGreater: credentials.password.match(eightCharsOrMore),
    confirmed:
      credentials.password.length >= 8 &&
      credentials.confirmPassword.length >= credentials.password.length &&
      credentials.confirmPassword === credentials.password
        ? true
        : credentials.confirmPassword.length >= credentials.password.length &&
          credentials.confirmPassword !== credentials.password
        ? false
        : undefined,
  };

  const passwordStrength = Object.values(passwordTracker).filter(
    (value) => value
  ).length;

  return (
    <React.Fragment>
      <div className="container-fluid px-0">
        <div style={{ marginTop: "0px" }}>
          <div style={{ backgroundColor: "#007bff", height: "2px" }}></div>
        </div>
      </div>

      <div className="row  justify-content-center mx-0 px-0 text-center mt-1">
        <div className="col-12 align-items-center col-md-10 col-lg-8 col-xl-6  row d-flex justify-content-center">
          <div className="col-12 text-center">
            {!isFetching && (
              <Typography
                variant="h6"
                className="py-3 text-uppercase font-weight-light text-center text-primary"
              >
                Change your password
              </Typography>
            )}
          </div>
          <div className="col-12 col-md-7 order-2 order-md-1">
            {isFetching && (
              <div className=" mt-5 mb-5 ">
                <img src={loadingGif} height="128" alt="loading" />
              </div>
            )}

            {!error && !isFetching && !successMessage && (
              <form onSubmit={onSubmit}>
                <FormControl
                  size="small"
                  fullWidth
                  variant="outlined"
                  className="border bg-white"
                >
                  <InputLabel htmlFor="password">New password</InputLabel>
                  <OutlinedInput
                    id="password"
                    placeholder="New password"
                    name="password"
                    type={credentials.showPassword ? "text" : "password"}
                    value={credentials.password}
                    onChange={(e) => {
                      const password = e.target.value;
                      setCredentials({ ...credentials, password });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disableFocusRipple
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setCredentials({
                              ...credentials,
                              showPassword: !credentials.showPassword,
                            })
                          }
                          edge="end"
                        >
                          {credentials.showPassword ? (
                            <span className="material-icons">
                              visibility_off
                            </span>
                          ) : (
                            <span className="material-icons">visibility</span>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="New password"
                  />
                </FormControl>

                <FormControl
                  size="small"
                  fullWidth
                  variant="outlined"
                  className="mt-3 bg-white"
                >
                  <InputLabel htmlFor="password">
                    Confirm New Password
                  </InputLabel>
                  <OutlinedInput
                    id="confirmPassword"
                    placeholder="Confirm New Password"
                    name="confirmPassword"
                    type={credentials.showConfirmPassword ? "text" : "password"}
                    value={credentials.confirmPassword}
                    onChange={(e) => {
                      const confirmPassword = e.target.value;
                      setCredentials({ ...credentials, confirmPassword });
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          disableFocusRipple
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setCredentials({
                              ...credentials,
                              showConfirmPassword:
                                !credentials.showConfirmPassword,
                            })
                          }
                          edge="end"
                        >
                          {credentials.showConfirmPassword ? (
                            <span className="material-icons">
                              visibility_off
                            </span>
                          ) : (
                            <span className="material-icons">visibility</span>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Confirm New Password"
                  />
                </FormControl>

                {!loading && (
                  <Button
                    type="submit"
                    disabled={
                      loading ||
                      credentials.confirmPassword !== credentials.password ||
                      passwordStrength < 6
                    }
                    variant="contained"
                    disableElevation
                    className="btn btn-block text-light mt-3"
                  >
                    Change password
                  </Button>
                )}

                {loading && (
                  <Button
                    type="button"
                    variant="contained"
                    disableElevation
                    className="btn btn-block text-light mt-3"
                  >
                    Changing password...
                  </Button>
                )}
              </form>
            )}

            {!!error && !isFetching && !successMessage && (
              <div className="alert alert-danger">{error}</div>
            )}

            {!isFetching && !!successMessage && (
              <div className="alert alert-success">{successMessage}</div>
            )}
          </div>

          {!error && !isFetching && !successMessage && (
            <div className="col-12 col-md-5 order-1 order-md-2">
              <Alert icon={false} severity="info" className="mb-2 rounded">
                <div className=" rounded text-left px-3 mb-3">
                  <div className="w-full rounded password-strength-status-dialog"></div>
                  <span>Password must include:</span>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.lowercase ? "text-success" : ""
                    }`}
                  >
                    {passwordTracker.lowercase ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">lowercase character(s)</span>
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.uppercase ? "text-success" : ""
                    }`}
                  >
                    {passwordTracker.uppercase ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">uppercase character(s)</span>
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.number ? "text-success" : ""
                    }`}
                  >
                    {passwordTracker.number ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">number(s)</span>
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.specialChar ? "text-success" : ""
                    }`}
                  >
                    {passwordTracker.specialChar ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">special character(s)</span>
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.eightCharsOrGreater ? "text-success" : ""
                    }`}
                  >
                    {passwordTracker.eightCharsOrGreater ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">eight or more characters </span>
                  </div>
                  <div
                    className={`d-flex flex-row align-items-center ${
                      passwordTracker.confirmed
                        ? "text-success"
                        : passwordTracker.confirmed === false
                        ? "text-danger"
                        : ""
                    }`}
                  >
                    {passwordTracker.confirmed ? (
                      <CheckCircleOutlineRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : passwordTracker.confirmed === false ? (
                      <UnpublishedRoundedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    ) : (
                      <CircleOutlinedIcon
                        style={{ fontSize: "16px", marginTop: "3px" }}
                      />
                    )}
                    <span className="ml-1">confirmed</span>
                  </div>
                </div>
              </Alert>
            </div>
          )}

          <div className="col-12 order-3">
            {!isFetching && (
              <Button
                type="button"
                onClick={() => {
                  history.push("/");
                }}
                className="pr-0 my-3  text-primary"
              >
                Back to Home
              </Button>
            )}
          </div>
        </div>
      </div>

      <style>
        {`
          .password-strength-status-dialog {
            height: 0.4rem;
            background-color: lightgrey;
            border-radius: 3px;
            margin: 0.5rem 0;
          }

          .password-strength-status-dialog::before {
            content: "";
            background-color: ${
              ["red", "orange", "orange", "orange", "orange", "#03a2cc"][
                passwordStrength - 1
              ] || ""
            };
            height: 100%;
            width: ${(passwordStrength / 6) * 100}%;
            display: block;
            border-radius: 3px;
            transition: width 0.2s;
          }
        `}
      </style>
    </React.Fragment>
  );
};

const mapStateToProps = ({ loading, user }) => {
  return { loading, user };
};
export default connect(mapStateToProps, { logout })(ResetPassword);
