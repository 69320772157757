import React from "react";
import swal from "sweetalert";
import html2canvas from "html2canvas";
import * as cocoSsd from "@tensorflow-models/coco-ssd";
import "@tensorflow/tfjs";
import "./Detections.css";
import { t } from "i18next";

export default class Detection extends React.Component {
  videoRef = React.createRef();
  canvasRef = React.createRef();

  constructor(props) {
    super(props);
    this.intervalId = null; // To store the interval ID
    this.lastSwalTimestamp = 0; // Timestamp to control swal calls
    this.SWAL_COOLDOWN = 5000; // 5 seconds cooldown
    this.captureImageHandler = this.captureImageHandler.bind(this);
  }

  triggerSwal(title, message, type) {
    const now = Date.now();
    if (now - this.lastSwalTimestamp > this.SWAL_COOLDOWN) {
      swal(title, message, type);
      this.lastSwalTimestamp = now;
    }
  }

  async detectPersonInImage(image) {
    const { errorCounts, setErrorCounts } = this.props;
    let newErrorCounts = { ...errorCounts };
    let message = "";
    if (!image) return;

    try {
      const imgElement = new Image();
      imgElement.src = image;
      await imgElement.decode();

      const model = await cocoSsd.load();
      const predictions = await model.detect(imgElement);

      const personCount = predictions.filter(
        (pred) => pred.class === "person"
      ).length;

      if (personCount === 0) {
        newErrorCounts.faceNotVisible += 1;
        message = t("noDetected");
        this.triggerSwal(
          t("noDetected"),
          t("returnToExam"),
          "error"
        );
      } else if (personCount > 1) {
        newErrorCounts.multipleFaces += 1;
        message = t("moreThanOne");
        this.triggerSwal(
          t("moreThanOne"),
          t("multipleDetected"),
          "error"
        );
      }
      // Update error counts
      if (message !== "") setErrorCounts(newErrorCounts, message);
    } catch (error) {
      console.error("Error during person detection:", error);
    }
  }

  async captureImageHandler() {
    const element = document.getElementById("videoRecordingCapturer");
    if (!element) {
      console.error("Element not found!");
      return;
    }

    try {
      const canvas = await html2canvas(element);
      const image = canvas.toDataURL("image/png");
      await this.detectPersonInImage(image);
    } catch (error) {
      console.error("Error capturing image:", error);
    }
  }

  async initializeCamera() {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      const webCamPromise = navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "user",
            width: 300,
            height: 225,
          },
        })
        .then((stream) => {
          window.stream = stream;
          this.videoRef.current.srcObject = stream;
          return new Promise((resolve) => {
            this.videoRef.current.onloadedmetadata = () => {
              resolve();
            };
          });
        });

      const modelPromise = cocoSsd.load();

      Promise.all([modelPromise, webCamPromise])
        .then((values) => {
          this.detectFrame(this.videoRef.current, values[0]);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  componentDidMount() {
    this.initializeCamera();
    // Start the interval to call captureImageHandler every 10 seconds
    this.intervalId = setInterval(this.captureImageHandler, 10000);
  }

  detectFrame = (video, model) => {
    model.detect(video).then((predictions) => {
      if (this.canvasRef.current) {
        this.renderPredictions(predictions);
        requestAnimationFrame(() => {
          this.detectFrame(video, model);
        });
      }
    });
  };

  renderPredictions = (predictions) => {
    const { errorCounts, setErrorCounts } = this.props;
    const ctx = this.canvasRef.current.getContext("2d");
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

    // Font options
    const font = "16px sans-serif";
    ctx.font = font;
    ctx.textBaseline = "top";

    // Initialize error counts for this frame
    let multipleFaceCount = 0;
    let newErrorCounts = { ...errorCounts };
    var message = "";

    const now = Date.now(); // Current timestamp

    predictions.forEach((prediction) => {
      const [x, y, width, height] = prediction.bbox;

      // Draw the bounding box
      ctx.strokeStyle = "#00FFFF";
      ctx.lineWidth = 2;
      ctx.strokeRect(x, y, width, height);

      // Draw the label background
      const textWidth = ctx.measureText(prediction.class).width;
      const textHeight = parseInt(font, 10);
      ctx.fillStyle = "#00FFFF";
      ctx.fillRect(x, y, textWidth + 8, textHeight + 8);

      // Check for specific classes
      if (prediction.class === "person") {
        multipleFaceCount++;
      } else if (now - this.lastSwalTimestamp > this.SWAL_COOLDOWN) {
        if (prediction.class === "cell phone") {
          message = t("cellPhoneDetected");
          this.triggerSwal(
            t("cellPhoneDetected"),
            t("actionRecorded"),
            "error"
          );
          newErrorCounts.cellPhone += 1;
        } else if (prediction.class === "book") {
          message = t("unAuthorizedObject");
          this.triggerSwal(
            t("unAuthorizedObject"),
            t("actionRecorded"),
            "error"
          );
          newErrorCounts.book += 1;
        } else if (prediction.class === "laptop") {
          message = t("unAuthorizedObject");
          this.triggerSwal(
            t("unAuthorizedObject"),
            t("actionRecorded"),
            "error"
          );
          newErrorCounts.laptop += 1;
        } else {
          message = t("faceNotDetected");
          this.triggerSwal(
            t("faceNotDetected"),
            t("returnToExam"),
            "error"
          );
          newErrorCounts.faceNotVisible += 1;
        }
      }

      // Draw the text
      ctx.fillStyle = "#000000";
      ctx.fillText(prediction.class, x, y);
    });

    // Check for multiple faces
    if (
      multipleFaceCount >= 2 &&
      now - this.lastSwalTimestamp > this.SWAL_COOLDOWN
    ) {
      message =
      t("unAuthorizedObject");
      newErrorCounts.multipleFaces += 1;
      this.triggerSwal(
        t("unAuthorizedObject"),
        t("onThe3rd"),
        "error"
      );
    }

    // Update error counts
    if (message !== "") setErrorCounts(newErrorCounts, message);

    // Update face detection count
    const count_facedetect = predictions.filter(
      (prediction) => prediction.class === "person"
    ).length;
    sessionStorage.setItem("count_facedetect", count_facedetect);
  };

  componentWillUnmount() {
    // Clear the interval to avoid memory leaks
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  render() {
    return (
      <div>
        <video
          className="size"
          autoPlay
          playsInline
          muted
          ref={this.videoRef}
          width="300"
          height="225"
          style={{
            boxShadow:
              "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.10)",
            left: "-120px",
            top: "-40px",
            borderRadius: "5px",
          }}
          id="videoRecordingCapturer"
        />
        <canvas
          className="size"
          ref={this.canvasRef}
          width="300"
          height="225"
          style={{
            left: "-120px",
            top: "-40px",
            borderRadius: "5px",
          }}
        />
      </div>
    );
  }
}
