import { Button, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  getApplications,
  // loadTestScore,
  // changeApplicationVisibility,
} from "../../../store/applicant/actions";

import NoResults from "../components/comm/NoResults";
import ReactPaginate from "react-paginate";
import SearchBox from "../components/comm/SearchBox";
import { sortApplicationByExamDesc } from "../../utils/sorting";

import AdvertSkeleton from "../components/comm/AdvertSkeleton";
import ApplicationItem from "../components/ApplicationItem";

import RightSideContent from "../../home/components/RightSideContent";

export const Applications = (props) => {
  const { loading, user, applications, getApplications } = props;

  const dispatch = useDispatch();

  const [searchInput, setSearchInput] = useState("");
  const [allHidden, setAllHidden] = useState(0);
  const [showHidden, setShowHidden] = useState(0);
  // const [showCandidates, setShowCandidates] = useState(null);
  // const [applicationId, setApplicationId] = useState("");
  // const [showAdvert, setShowAdvert] = useState(null);

  // ### PAGINATION
  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 5;
  const endOffset = itemOffset + itemsPerPage;

  useEffect(() => {
    setFilteredJobs(
      applications.filter((application) =>
        (
          (application.positionName || "") +
          (application.entityName || "") +
          (application.apStatusName || "") +
          (application.writtenExamStatus || "") +
          (application.oralExamStatus || "") +
          (application.jobOfferStatusName || "") +
          (application.waitingListStatusName || "") +
          (application.entityAcronym || "")
        )
          .replace(/\s/g, "")
          .toLowerCase()
          .includes(searchInput.replace(/\s/g, "").toLowerCase())
      )
    );
  }, [applications, searchInput]);

  useEffect(() => {
    if (!applications.length) getApplications(showHidden, setAllHidden);
  }, [showHidden]);

  // const isThereHiddenApplication = applications
  //   ? applications.find((item) => item.isHidden)
  //     ? true
  //     : false
  //   : false;

  // useEffect(() => {
  //   if (!isThereHiddenApplication && showHidden) {
  //     setShowHidden(false);
  //   }
  //   return () => setShowHidden(false);
  // }, [isThereHiddenApplication]);

  // const DATA_TO_MAP = applications
  //   ? sortApplicationByExamDesc(applications).filter(
  //       (item) => item.isHidden === showHidden
  //     )
  //   : null;

  // const PAGINATED_APPLICATIONS = DATA_TO_MAP
  //   ? DATA_TO_MAP.slice(itemOffset, endOffset)
  //   : [];
  // const NUMBER_OF_PAGES = DATA_TO_MAP
  //   ? Math.ceil(DATA_TO_MAP.length / itemsPerPage)
  //   : 1;

  // const handlePageClick = (event) => {
  //   const newOffset =
  //     (event.selected * itemsPerPage) % (DATA_TO_MAP ? DATA_TO_MAP.length : 1);
  //   setItemOffset(newOffset);
  // };

  // const DATA_Application = DATA_TO_MAP
  //   ? DATA_TO_MAP.find((item) => item.id === applicationId)
  //   : undefined;

  const randomArray = Array.from({ length: 10 }, () =>
    Math.floor(Math.random() * 10)
  );

  const [filteredJobs, setFilteredJobs] = useState([]);

  return (
    <div className="row mx-0 mt-4">
      <div className="col-12 mb-3 col-sm-4 col-md-3 order-first order-sm-last px-1 px-sm-3">
        {/* <ProfileSummary /> */}

        <RightSideContent />

        {/* <div className="d-none d-md-block">
          <ApplicationGuidelines expanded={true} />
        </div>
        <div className="d-block d-md-none ">
          <ApplicationGuidelines expanded={false} />
        </div> */}
      </div>
      <div className="col-12 col-sm-8 col-md-9 order-last order-sm-first mt-sm-0 px-1 px-sm-3">
        <div className="card mb-3 elevated rounded border border-primary bg-light mt-4">
          {/* {DATA_Application ? (
          <Appeals
            application={DATA_Application}
            onBack={() => setApplicationId(null)}
          />
        ) : showAdvert ? (
          <ApplicationAdvertDetails
            requisitionId={showAdvert.requisitionId}
            advertisementId={showAdvert.advertisementId}
            onBack={() => setShowAdvert(null)}
          />
        ) : showCandidates ? (
          <ShowApplicantCandidates
            advertisementId={showCandidates}
            onBack={() => setShowCandidates(null)}
          />
        ) : ( */}

          <div>
            <div className="card-header bg-light rounded-top font-weight-bold ">
              <div className="my-2 d-flex flex-column flex-md-row justify-content-md-between align-items-center ">
                <Typography
                  variant="h6"
                  className={`text-uppercase ${
                    showHidden ? "text-danger" : ""
                  }`}
                >
                  <span className="mr-2">
                    {!showHidden ? "My" : " Hidden"} applications (
                    {applications.length})
                  </span>

                  <Button
                    variant="text"
                    disabled={loading || !allHidden}
                    onClick={() => {
                      dispatch({ type: "SET_APPLICATIONS", data: [] });

                      setShowHidden(+!showHidden);
                      setItemOffset(0);
                    }}
                    size="small"
                    className="px-0"
                  >
                    <span className="">
                      Show {showHidden ? " Unhidden" : " Hidden"}
                    </span>
                    {/* <span className="d-block d-sm-none">
                      {showHidden ? "Unhidden" : "Hidden"}
                    </span> */}
                  </Button>
                </Typography>

                <div className="d-flex flex-row align-items-center mt-2 justify-content-between">
                  <div className="">
                    <SearchBox
                      placeholder="Search..."
                      disabled={!applications.length || loading}
                      onSearch={setSearchInput}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              {loading && !applications.length ? (
                randomArray.map((item) => (
                  <div className="mb-3" key={item + Math.random() * 11}>
                    <AdvertSkeleton />
                  </div>
                ))
              ) : filteredJobs.length ? (
                filteredJobs.map((application, index) => (
                  <div className="mb-3" key={application.id}>
                    <ApplicationItem
                      application={application}
                      totalQuestions={0}
                    />
                  </div>
                ))
              ) : (
                <NoResults />
              )}

              {/* display pagination */}
              {/* {applications.length >= itemsPerPage && (
                <div
                  className="justify-content-center col-12 mb-3"
                  style={{ overflow: "hidden" }}
                >
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <span className="pr-1 d-none d-md-inline">
                          Next
                        </span>
                        <i className="fas fa-angle-double-right"></i>
                      </>
                    }
                    previousLabel={
                      <>
                        <i className="fas fa-angle-double-left"></i>
                        <span className="pl-1  d-none d-md-inline">
                          Previous
                        </span>
                      </>
                    }
                    pageRangeDisplayed={3}
                    marginPagesDisplayed={1}
                    onPageChange={handlePageClick}
                    pageCount={NUMBER_OF_PAGES}
                    renderOnZeroPageCount={null}
                    containerClassName="pagination"
                    pageLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    previousLinkClassName="btn btn-outline-info btn-sm mr-1 ml-1 mt-1"
                    nextLinkClassName="btn btn-outline-info btn-sm ml-1 mt-1"
                    activeLinkClassName="active"
                  />
                </div>
              )} */}
            </div>
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ user, loading, applications }) => ({
  user,
  loading,
  applications,
});

export default connect(mapStateToProps, {
  getApplications,
  // loadTestScore,
  // changeApplicationVisibility,
})(Applications);

// const doSearch = (data, value) => {
//   if (value.length === 0) return data;
//   const _value = value.toLowerCase();
//   return data.filter(
//     (item) =>
//       item.positionName.toLowerCase().includes(_value) ||
//       item.entityAcronym.toLowerCase().includes(_value) ||
//       item.entityName.toLowerCase().includes(_value)
//   );
// };
