import React, { useEffect } from "react";
import "./SystemCheck.css";
import DetectRTC from "detectrtc";
import swal from "sweetalert";
import ExampleComponent from "../technicalvalidation/ExampleComponent";
import { CheckCircle } from "@mui/icons-material";
import { useTranslation } from "react-i18next";

function ValidateCheck(callBack) {
  // Helper function to check browser compatibility
  function checkBrowserCompatibility() {
    const browser = DetectRTC.browser;
    if (
      (browser.isChrome && browser.version > 80) ||
      (browser.isFirefox && browser.version > 60) ||
      (browser.isSafari && browser.version > 12) ||
      (browser.isOpera && browser.version > 60) ||
      (browser.isEdge && browser.version > 80)
    ) {
      return true;
    } else {
      swal("Please Update Browser or Try a Different Browser");
      return false;
    }
  }

  // Helper function to check camera permissions
  function checkCameraPermissions(callback) {
    DetectRTC.load(function () {
      const hasWebcamPermission = DetectRTC.isWebsiteHasWebcamPermissions;

      if (hasWebcamPermission) {
        callback(true);
      } else {
        navigator.mediaDevices
          .getUserMedia({ video: true })
          .then(function (stream) {
            stream.getTracks().forEach((track) => track.stop());
            callback(true);
          })
          .catch(function () {
            callback(false);
          });
      }
    });
  }

  // Initialize result object
  const result = {
    browser: false,
    camera: false,
  };

  // Check browser compatibility
  result.browser = checkBrowserCompatibility();

  // Check camera permissions
  checkCameraPermissions((cameraPermission) => {
    result.camera = cameraPermission;

    // Final callback with result
    callBack(result);
  });
}

const SystemCheck = ({ callBack, setIsOperatingSystemAllowed }) => {
  // System Detections Package
  var DetectRTC = require("detectrtc");
  const { t } = useTranslation();

  ValidateCheck(callBack);

  useEffect(() => {
    // Load DetectRTC and determine the operating system
    DetectRTC.load(() => {
      if (DetectRTC.osName) {
        const allowedOS = ["Windows", "MacOSX", "MacOS", "Linux"]; // List of allowed operating systems
        const currentOS = DetectRTC.osName.replace(/\s+/g, ""); // Get the name of the operating system
        setIsOperatingSystemAllowed(allowedOS.includes(currentOS)); // Check if the OS is allowed
        //setIsOperatingSystemAllowed(false)
      } else {
        console.error("DetectRTC.os or DetectRTC.os.name is undefined.");
        setIsOperatingSystemAllowed(false); // Default to disallowed if OS can't be determined
      }
    });
  }, []);

  return (
    <body className="App-header">
      <div className="main">
        <table align="center">
          <tbody>
            <tr>
              <td>
                <ul>
                  <li className="test">
                    <div>{t("allowedOs")}</div>
                    <ul>
                      <li>
                        <span>
                          <b>Operating System:</b>{" "}
                          {" " +
                            JSON.stringify(DetectRTC.osName, null, 2).slice(
                              1,
                              -1
                            ) +
                            " " +
                            JSON.stringify(DetectRTC.osVersion, null, 0).slice(
                              1,
                              -1
                            )}{" "}
                          <CheckCircle
                            color="success"
                            style={{ fontSize: 18 }}
                          />
                        </span>
                      </li>
                      <li>Windows 11</li>
                      <li>MacOs</li>
                      <li>Ubuntu</li>
                    </ul>
                  </li>
                  <li className="test">
                    {t("allowedBrowser")}
                    <ul>
                      <li>
                        <span>
                          <b>Browser:</b>{" "}
                          {" " +
                            JSON.stringify(DetectRTC.browser.name).slice(
                              1,
                              -1
                            ) +
                            " " +
                            JSON.stringify(DetectRTC.browser.version)}{" "}
                        </span>
                        <CheckCircle color="success" style={{ fontSize: 18 }} />
                      </li>
                      <li>Chrome</li>
                      <li>Firefox</li>
                      <li>Safari</li>
                    </ul>
                  </li>
                  {/* <li>
                    <span>
                      <b>Camera:</b>The lighting in the room must be bright
                      enough to be considered “daylight” quality.
                      <button onClick={initializeScreenCapture}>Check Camera</button>
                    </span><CheckCircleOutlineIcon/>
                  </li> */}
                  <li>
                    <span>
                      <ExampleComponent />
                    </span>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </body>
  );
};

let screenStream = null;
// Request screen-sharing access once
const initializeScreenCapture = async () => {
  try {
    screenStream = await navigator.mediaDevices.getDisplayMedia({
      video: true, // Only video needed
    });

    console.log("Screen sharing initialized");

    // Optionally display the shared screen in a hidden video element
    const videoElement = document.getElementById("screen-video");
    if (videoElement) {
      videoElement.srcObject = screenStream;
      videoElement.play();
    }

    // Listen for when the user stops sharing manually
    screenStream.getVideoTracks()[0].addEventListener("ended", () => {
      console.log("User stopped screen sharing");
      screenStream = null; // Reset the stream
    });

    return true; // Indicate success
  } catch (error) {
    console.error("Failed to initialize screen capture:", error);
    return false; // Indicate failure
  }
};

export default SystemCheck;
