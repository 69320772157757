import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import CurrencyFormat from "react-currency-format";
import colors from "../../utils/colors";
import moment from "moment/moment";
import CountDownTimer from "./comm/CountDownTimer";
import { connect, useDispatch } from "react-redux";
import AdvertisementDetails from "./AdvertisementDetails";
function AdvertisementItem(props) {
  const {
    advertisement,
    hideTime,
    roundedValue,
    isForView,
    isHome,
    totalQuestions,
  } = props;

  const dispatch = useDispatch();

  const [showAdvertisementDetails, setShowAdvertisementDetails] =
    useState(false);

  return (
    <>
      <Card
        className={`border ${roundedValue || "rounded"} bg-light w-full h-100`}
        elevation={isForView ? 0 : 1}
      >
        <CardHeader
          className="pb-0"
          title={
            <Typography
              sx={{ fontSize: 16 }}
              variant="h6"
              color={colors.blueLogo}
              className="  d-flex justify-content-between"
            >
              <span
                onClick={() => {
                  if (!isForView) setShowAdvertisementDetails(true);
                }}
                className="cursor-pointer "
              >
                <span className="text-underline ">
                  {advertisement.positionName}
                </span>{" "}
              </span>
            </Typography>
          }
          subheader={
            <Typography variant="h6" component="div" sx={{ fontSize: 13 }}>
              <span className="text-capitalize">
                {advertisement.entityName?.toLowerCase()}{" "}
                {!!advertisement.entityAcronym && (
                  <>({advertisement.entityAcronym})</>
                )}
              </span>
            </Typography>
          }
          action={
            <span>
              {((!isForView && !advertisement.wasApplied) || isHome) && (
                <Button
                  size="small"
                  color="primary"
                  className="py-0"
                  variant="contained"
                  disableRipple
                  onClick={() => setShowAdvertisementDetails(true)}
                >
                  Apply
                </Button>
              )}

              {advertisement.wasApplied && !isHome && (
                <Button
                  size="small"
                  color="primary"
                  className="py-0"
                  variant="contained"
                  disableRipple
                  disabled
                >
                  Applied
                </Button>
              )}
            </span>
          }
        />
        <CardContent className="pt-0">
          <div className="d-flex align-items-center flex-wrap">
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Level:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {`${advertisement.levelName}.${advertisement.scaleName}`}
              </Typography>
            </div>
            <div className="mr-2">
              <Typography variant="caption" style={{ color: colors?.blueLogo }}>
                Post{advertisement.numberOfPosts > 1 ? "s" : ""}:
              </Typography>
              <Typography
                variant="caption"
                className="font-weight-bold"
                style={{ fontSize: "13px" }}
              >
                {advertisement.numberOfPosts}
              </Typography>
            </div>
            <span>
              <span
                className={`badge badge-pill border`}
                style={{ fontSize: 10 }}
              >
                {advertisement.employeeGroupName}
              </span>{" "}
              {advertisement.isInternal && (
                <span className="badge badge-info ml-2">Internal</span>
              )}
            </span>
          </div>
          <div className="d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center flex-wrap">
            <div className="d-flex flex-nowrap">
              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <CalendarMonthOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Posted on</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(advertisement.openingDate).format("ll")}
                </Typography>
              </div>

              <div className="d-flex flex-column mr-3">
                <div style={{ color: colors.blueLogo }}>
                  <AccessTimeOutlinedIcon
                    style={{ fontSize: "12px", marginRight: "4px" }}
                  />
                  <Typography variant="caption">Deadline</Typography>
                </div>
                <Typography
                  variant="body2"
                  className="font-weight-light"
                  style={{ fontSize: "13px" }}
                >
                  {moment(advertisement.closingDate).format("ll")}
                </Typography>
              </div>
            </div>
            <div className="mx-auto mx-md-0 mt-2 mb-n3 mt-1 mt-md-0">
              {!hideTime && (
                <CountDownTimer
                  counterType="small"
                  defaultLeftDifferent={
                    advertisement.closingTimeLeft
                      ? advertisement.closingTimeLeft
                      : 0
                  }
                  onTimeOut={() =>
                    dispatch({
                      type: "DELETE_ADVERTISEMENT",
                      id: advertisement.id,
                    })
                  }
                />
              )}
            </div>
          </div>
        </CardContent>
      </Card>

      {showAdvertisementDetails && (
        <AdvertisementDetails
          isHome={isHome}
          showDialog={showAdvertisementDetails}
          setShowDialog={setShowAdvertisementDetails}
          advertisement={advertisement}
        />
      )}
    </>
  );
}

export default AdvertisementItem;
