import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: {
      lang: "English",
      selectOne: "Select only one answer",
      chooseLanguage: "Choose a test language",
      psychometricTitle: "Psychometric Test",
      psychometricDescription:
        "Psychometric tests are assessments used to measure psychological attributes such as intelligence, aptitude, personality, and behavior.",
      totalQuestions: "Total questions",
      back: "Back",
      continue: "Continue",
      acceptAndContinue: "Accept and Start your Psychometric Test",
      checkingTheSystem: "Checking the system and your computer",
      testRules: "Psychometric Test rules",
      cameraTest: "Camera Testing",
      allowedOs: "Allowed Operating Systems",
      allowedBrowser: "Allowed Browsers",
      internetSpeed: "Internet speed",
      cameraDetection:
        "Please hold your national ID on your chest, face the computer camera, and take your picture",
      detectFace: "Detect Candidate",
      language: "Languages",
      examRules: "Exam Rules",
      permissions: "Permissions",
      examDescription: `This psychometric exam is a standardized test designed to measure a
        candidate's cognitive abilities, personality traits, and behavioral
        tendencies. We outline essential rules and guidelines for conducting
        online psychometric exams, ensuring fairness, integrity, and a
        standardized testing environment. These rules are designed to enhance
        the reliability of the assessment process and maintain the validity of
        the results.`,
      examCondition:
        "A candidate who will be allowed to take further exams is the one who scores at least 50% in this exam.",
      timeManagement: "Time management",
      timeManagementRuleT: "Strict time limit",
      timeManagementRuleS:
        "Candidates must complete each question within the allotted time, with no extensions permitted.",
      timeManagementRule2: "21 Questions for 15 minutes",
      technicalRequirements: "Technical requirements",
      supportedOs: "Operating System (OS) Compatibility",
      supportedBrowser: "Permitted Browsers",
      internetDevice: "Internet Connection and Device",
      minInternet: "Minimum internet speed",
      recommendedDevice: "Recommended device",
      examEnvironment: "Exam environment rules",
      quiteSpaceT: "Quiet Space Requirement",
      quiteSpaceS:
        "A quiet location with no other person allowed in the room during the exam.",
      clearDeskT: "Clear desk policy",
      clearDeskS:
        "The desk or workspace must be clear of unauthorized items, including books, notes, or electronic devices not explicitly permitted.",
      codeOfConduct: "Code of Conduct",
      codeOfConduct1T: "No Cheating or Collusion",
      codeOfConduct1S:
        "Candidates must complete the exam independently without assistance from others or the use of unauthorized resources. Cheating, rule violations, or failure to follow instructions may result in disqualification and reporting to relevant authorities.",

      codeOfConduct2T: "Penalties for Violations",
      codeOfConduct2S:
        "Cheating, rule violations, or failure to follow instructions may result in disqualification and reporting to relevant authorities.",
      confirmRules: "I Agree to the above instructions",
      camera: "Camera",
      cameraDescription:
        " To ensure accountability, verify identity, and uphold the integrity of the exam process.",
      microphone: "Microphone",
      microphoneDescription:
        "To ensure integrity and verify the candidate's compliance with the rules.",
      os: "Operating System",
      osDescription:
        "To ensure compatibility, stability, and optimal performance during the exam.",
      browser: "Browser",
      browserDescription:
        "To ensure compatibility with the exam system, providing a smooth experience.",
      internetDescription:
        "To ensure a stable connection, essential for completing the exam smoothly without interruptions.",
      soundLevel: "Sound Level",
      soundDescription:
        "To ensure that the environment remains quiet and free from distractions, and  maintain integrity.",
      computerToProjector:
        "Connecting your computer to a projector during the exam is prohibited.",
      cameraNotAllowed: "Please Allow the camera Access",
      micNotAllowed: "Please Allow the Microphone Access",
      osNotAllowed: "Please use, Windows 10+, MacOs, or UBUNTU",
      browserNotAllowed: "Please use only allowed browser",
      webAndMic: "Webcam and Microphone Use",
      validId: "Valid ID Requirement",
      validIdDesc:
        "Candidates must present a government-issued photo ID, and the name on the registration form must match the one on the ID",
      webcamDesc:
        "The webcam and microphone must be enabled and accessible throughout the exam. Recording may be used to review any suspicious behavior",
      proctoringConcent:
        "By starting this exam, you agree to be monitored and recorded via your camera. The exam will begin once the countdown ends and will be graded immediately. You will not be able to cancel or retake it. If you are not ready, please cancel now.",
      detecting: "Detecting ...",
      personDetected: "Person detected!",
      moreThanOne: "More than one person detected!",
      noDetected: "No Person detected!",
      startExam: "Start the Test!",
      noCancel: "No, Cancel",
      yesStart: "Yes, Start",
      getReady: "Get ready for the Test...",
      starting: "Starting...",
      cellPhoneDetected:
        "Cell phone detected. Please ensure no devices are present to continue.",
      faceNotDetected:
        "Your face is not detected. Please ensure your face is clearly visible to continue.",
      multipleDetected:
        "People were detected. Please ensure only one person is present for the exam.",
      actionRecorded: "Action has been Recorded",
      returnToExam: "Please return to the examination view!",
      error: "Error",
      windowMinT: "Window minimized",
      windowMinD:
        "Your exam activity is being monitored.\nSwitching tabs or minimizing the window during the exam is strictly prohibited and may result in disciplinary action, including exam cancellation and potential academic penalties.\nPlease return to the exam window immediately and keep your focus on the assessment.",
      unAuthorizedObject:
        "The system has detected an unauthorized object in the camera view.",
      onThe3rd: "On the third warning, Your exam will be terminated",
      mouseLeftBrowserTitle: "Mouse left the browser",
      mouseLeftBrowserMessage: `Your exam activity is being monitored.\nLeaving the browser window or tab during the exam is strictly prohibited and may result in disciplinary action, including exam cancellation.\nPlease keep your focus on the exam and avoid any unauthorized actions.\nYour cooperation is essential to maintain the integrity of this assessment.`,
      cameraWtitle: "Warning: Camera Permissions Required",
      cameraWmessage:
        "To proceed, please enable camera access by closing this prompt and granting the necessary permissions.",
      noPermissionsT: "Permissions could not be verified!",
      noPermissionsD: "Ensure all required permissions are granted!",
      noCameraT: "Detect Candidate step!",
      noCameraD:
        "Please complete the 'Detect Candidate' step before proceeding.",
      accountLocked: "Access to this post is suspended",
      examTerminated: "Exam Terminated for Misconduct!",
      sessionTerminated:
        "Your exam session has been terminated due to your repeated suspicious  behaviors. This decision is final.",
      loadingWait: "Loading, Please wait…",
      clickToContinue: "Click to continue",
      saveContinue: "Save & Continue",
      saveSubmit: "Save & Submit the Test",
      countDown: "Count down",
      completedQuestions: "Completed Questions",
      secondsRem: "Seconds remaining",
      of: "of",
      finished: "Finished",
      welcomeBack: "Welcome Back!",
      welcomeBack1: "Ready to pick up where you left off? You previously selected",
      welcomeBack2: "as your test language.",
      welcomeBack3: "Please click ‘Continue’ to proceed.",
    },
  },
  fr: {
    translation: {
      lang: "French",
      selectOne: "Sélectionnez une réponse",
      chooseLanguage: "Choisissez une langue de test",
      psychometricTitle: "Test psychométrique",
      psychometricDescription:
        "Les tests psychométriques sont des évaluations utilisées pour mesurer des attributs psychologiques tels que l'intelligence, l'aptitude, la personnalité et le comportement.",
      totalQuestions: "Toutes les questions",
      back: "Retour",
      continue: "Continuer",
      acceptAndContinue: "Acceptez et commencez votre test psychométrique",
      checkingTheSystem: "Vérification du système et de votre ordinateur",
      testRules: "Règles du test psychométrique",
      cameraTest: "Test de caméra",
      allowedOs: "Systèmes d'exploitation autorisés",
      allowedBrowser: "Navigateurs autorisés",
      internetSpeed: "Vitesse d'Internet",
      cameraDetection:
        "Tenez votre pièce d'identité devant la caméra, avec votre visage clairement visible.",
      detectFace: "Détecter le visage",
      language: "Langues",
      examRules: "Règles de l'examen",
      permissions: "Autorisations",
      examDescription: `Cet examen psychométrique est un test standardisé conçu pour mesurer les capacités cognitives, les traits de personnalité et les tendances comportementales d'un candidat. Nous énonçons les règles et lignes directrices essentielles pour la réalisation des examens psychométriques en ligne, afin d'assurer l'équité, l'intégrité et un environnement de test standardisé. Ces règles visent à renforcer la fiabilité du processus d'évaluation et à maintenir la validité des résultats.`,
      examCondition:
        "Le candidat qui sera autorisé à passer les autres examens est celui qui obtiendra au moins 50 % à cet examen",
      timeManagement: "Gestion du temps",
      timeManagementRuleT: "Limite de temps stricte",
      timeManagementRuleS:
        "Les candidats doivent terminer chaque question dans le temps imparti, sans extensions autorisées.",
      timeManagementRule2: "21 questions pour 15 minutes",
      technicalRequirements: "Exigences techniques",
      supportedOs: "Compatibilité du Système d'Exploitation (OS)",
      supportedBrowser: "Navigateurs autorisés",
      internetDevice: "Connexion Internet et appareil",
      minInternet: "Vitesse minimale d'Internet",
      recommendedDevice: "Appareil recommandé",
      examEnvironment: "Règles de l'environnement d'examen",
      quiteSpaceT: "Exigence d'espace calme",
      quiteSpaceS:
        "Vous devez passer l'examen dans un lieu calme, exempt d'interruptions (ex. : pas d'appels téléphoniques, conversations ou bruits extérieurs).",
      clearDeskT: "Politique de Bureau propre",
      clearDeskS:
        "Le bureau ou l'espace de travail doit être exempt d'articles non autorisés, y compris des livres, des notes ou des appareils électroniques non explicitement autorisés.",
      codeOfConduct: "Code de Conduite",
      codeOfConduct1T: "Interdiction de la Tricherie ou de la Collusion",
      codeOfConduct1S:
        "Les candidats doivent terminer l'examen de manière indépendante, sans assistance extérieure ni utilisation de ressources non autorisées.La tricherie, les violations des règles ou le non-respect des instructions peuvent entraîner une disqualification et un signalement aux autorités compétentes",
      codeOfConduct2T: "Sanctions en Cas de Violations",
      codeOfConduct2S:
        "La tricherie, les violations des règles ou le non-respect des instructions peuvent entraîner une disqualification et un signalement aux autorités compétentes",
      confirmRules: "J'accepte les instructions ci-dessus",
      camera: "Camera",
      cameraDescription:
        "Pour garantir la responsabilité, vérifier l'identité et préserver l'intégrité du processus d'examen.",
      microphone: "Microphone",
      microphoneDescription:
        "Garantir l'intégrité et vérifier que le candidat respecte les règles.",
      os: "Système d'Exploitation (OS)",
      osDescription:
        " Garantir la compatibilité, la stabilité et des performances optimales pendant l'examen.",
      browser: "Navigateurs",
      browserDescription:
        "Pour garantir la compatibilité avec le système d'examen, offrant une expérience excellente",
      internetDescription:
        "Pour assurer une connexion stable, essentielle pour passer l'examen sans interruptions.",
      soundLevel: "Niveau sonore",
      soundDescription:
        "Pour s'assurer que l'environnement reste calme et sans distractions, et maintenir l'intégrité de l'examen",
      computerToProjector:
        "Il est interdit de connecter votre ordinateur à un projecteur pendant l'examen.",
      proctoringConcent:
        "En débutant cet examen, vous acceptez d'être surveillé et enregistré via votre caméra. L'examen commencera dès la fin du compte à rebours et sera noté immédiatement. Vous ne pourrez ni l'annuler ni le refaire. Si vous n'êtes pas prêt, annulez maintenant.",
      webAndMic: "Utilisation de la Webcam et du Microphone",
      validId: "Exigence d'une pièce d'identité valide",
      validIdDesc:
        "Les candidats doivent présenter une pièce d'identité avec photo émise par le gouvernement, et le nom sur le formulaire d'inscription doit correspondre à celui de la pièce.",
      webcamDesc:
        "La webcam et le microphone doivent rester activés, l'enregistrement pouvant vérifier tout comportement suspect.",
      detecting: "Détection en cours...",
      personDetected: "Personne détectée !",
      moreThanOne: "Plus d'une personne détectée !",
      noDetected: "Aucune personne détectée !",
      startExam: "Commencer le test !",
      noCancel: "Non, Annuler",
      yesStart: "Oui, Commencer",
      getReady: "Préparez-vous pour le test...",
      starting: "Démarrage...",
      cellPhoneDetected:
        "Téléphone portable détecté. Assurez-vous qu'aucun appareil ne soit présent pour continuer.",
      faceNotDetected:
        "Votre visage n'est pas détecté. Veuillez vous assurer que votre visage est bien visible pour continuer.",
      multipleDetected:
        "Plusieurs personnes détectées. Veuillez vous assurer qu'une seule personne soit présente pour l'examen.",
      actionRecorded: "Avertissement : Action enregistrée",
      returnToExam: "Veuillez revenir à la vue de l'examen.",
      error: "Erreur",
      windowMinT: "Fenêtre minimisée",
      windowMinD:
        "Votre activité d'examen est surveillée.\nChanger d'onglet ou minimiser la fenêtre pendant l'examen est strictement interdit et peut entraîner des mesures disciplinaires, y compris l'annulation de l'examen et des sanctions académiques potentielles.\nVeuillez revenir immédiatement à la fenêtre de l'examen et restez concentré sur l'évaluation.",
      unAuthorizedObject:
        "Un objet non autorisé a été détecté dans le champ de vision de la caméra.",
      onThe3rd: "Après le troisième avertissement, votre examen sera annulé.",
      mouseLeftBrowserTitle: "La souris a quitté le navigateur",
      mouseLeftBrowserMessage: `Votre activité d'examen est surveillée.\nQuitter la fenêtre ou l'onglet du navigateur pendant l'examen est strictement interdit et peut entraîner des sanctions disciplinaires, y compris l'annulation de l'examen.\nVeuillez rester concentré sur l'examen et éviter toute action non autorisée.\nVotre coopération est essentielle pour maintenir l'intégrité de cette évaluation.`,
      cameraWtitle: "Avertissement : Autorisations de caméra requises",
      cameraWmessage:
        "Pour continuer, veuillez activer l'accès à la caméra en fermant cette invite et en accordant les autorisations nécessaires.",
      noPermissionsT: "Les permissions n’ont pas été validées!",
      noPermissionsD:
        "Veuillez vous assurer que toutes les autorisations nécessaires sont accordées",
      noCameraT: "l'étape 'Détecter le candidat!",
      noCameraD:
        "Veuillez compléter l'étape 'Détecter le candidat' avant de continuer.",
      accountLocked: "Exam Terminated for Misconduct",
      examTerminated: "Examen terminé pour inconduite !",
      sessionTerminated:
        "Votre session d'examen a été annulée en raison de vos comportements suspects répétés. Cette décision est finale.",
      loadingWait: "Chargement en cours, veuillez patienter…",
      clickToContinue: "Tegereza gato",
      countDown: "Compte à rebours",
      completedQuestions: "Questions complétées",
      secondsRem: "Secondes restantes",
      of: "de",
      saveContinue: "Enregistrer et continuer",
      saveSubmit: "Enregistrer et soumettre le test",
      finished: "Terminé",
      welcomeBack: "Bienvenue encore!",
      welcomeBack1: "Prêt à reprendre là où vous êtiez? Vous avez précédemment sélectionné",
      welcomeBack2: "comme langue de test.",
      welcomeBack3: "Cliquer sur ‘Continuer’ pour avancer.",
    },
  },
  kiny: {
    translation: {
      lang: "Kinyarwanda",
      selectOne: "Hitamo igisubizo kimwe gusa",
      chooseLanguage: "Hitamo ururimi rw'ikizamini",
      psychometricTitle: "Ikizami cya Psychometric",
      psychometricDescription:
        "Ibizamini bya psychometric bigamije gupima ubushobozi bwo gutekereza, imyitwarire n'imico y'umuntu.",
      totalQuestions: "Ibibazo byose",
      back: "Subira inyuma",
      continue: "Komeza",
      acceptAndContinue:
        "Tangire ikizamini cyawe",
      checkingTheSystem: "Kugenzura sisitemu n'ibikoresho byawe",
      testRules: "Amategeko y'ikizamini cya psychometric",
      cameraTest: "Isuzuma rya Kamera",
      allowedOs: "Sisitemu zemerewe",
      allowedBrowser: "Browsers zemerewe",
      internetSpeed: "Umuvuduko w'Internet",
      cameraDetection:
        "Reba neza muri kamera, wifate ifoto ugaragaza icyangombwa cyawe muri kamera",
      detectFace: "Ifate ifoto",
      language: "Indimi",
      examRules: "Amategeko y'ikizamini",
      permissions: "Uburenganzira",
      examDescription: `Iki kizamini cya psychométrique ni ikizamini gisanzwe gikoreshwa mu gupima ubushobozi bw'umukandida mu mitekerereze, imico ye, n'imyitwarire. Dutangaza amategeko n'amabwiriza yo gukoresha ikizamini cya psychométrique binyujijwe ku rubuga, hagamijwe gutanga umucyo nkuko ibindi bizamini bikorwa.
 Aya mategeko yateguwe mu rwego rwo kongera icyizere mu kugenzura ibizamini no kubungabunga ubuziranenge bw'ibyavuyemo.
`,
      examCondition:
        "Umukandida uzemererwa gukora ibindi bizamini, ni uzagira nibura 50% by' iki kizamini",
      timeManagement: "Kugenzura Igihe",
      timeManagementRuleT: "Igihe cyagenwe",
      timeManagementRuleS:
        "Buri kibazo gifite igihe ntarengwa gikwiye. Abakandida bagomba kurangiza buri kibazo k’ikizamini mu gihe cyagenwe, nta kwongererwa igihe byemewe.",
      timeManagementRule2: "Ibibazo 21 mu minota 15",
      technicalRequirements: "Ibisabwa by'ikoranabuhanga",
      supportedOs: "Guhuza na Sisitemu y'Imikorere (OS)",
      supportedBrowser: "Ubwoko bw’imbuga za murandasi zikoreshwa",
      internetDevice: "Ibisabwa kuri mudasobwa n’igipimo cya murandasi",
      minInternet: "Murandasi ikenewe",
      recommendedDevice: "Mudasobwa ikenewe",
      examEnvironment: "Amabwiriza y'aho gukorera Ikizamini",
      quiteSpaceT: "Ahantu hatuje",
      quiteSpaceS:
        "Ugomba gukorera ikizamini ahantu hatuje, nta rusaku (nko kuganira, gukoresha telefone cyangwa ibindi bihungabanya umutuzo)",
      clearDeskT: "Umucyo n’urumuri",
      clearDeskS:
        "Korera ku meza atariho ikindi kintu kandi mu cyumba gifite urumuri ruhagije",
      codeOfConduct: "Amabwiriza yo gukora neza",
      codeOfConduct1T: "Nta kubeshya cyangwa kwishyira hamwe",
      codeOfConduct1S:
        "Abakandida bagomba gukora ikizamini bonyine nta gufashwa n'abandi cyangwa gukoresha ibikoresho bitemewe. Gukopera, kurenga ku mategeko, cyangwa kudakurikiza amabwiriza bishobora gutuma uhagarikwa mu kizamini, ndetse ukaba wakurikiranwa n’inzego zibifitiye ububasha.",
      codeOfConduct2T: "Ibihano byo kurenga ku mabwiriza n’amategeko",
      codeOfConduct2S:
        "Gukopera, kurenga ku mategeko, cyangwa kudakurikiza amabwiriza bishobora gutuma uhagarikwa mu kizamini, ndetse ukaba wakurikiranwa n’inzego zibifitiye ububasha.",
      confirmRules: "Nemeye amategeko na amabwiriza",
      camera: "Camera",
      cameraDescription:
        "Kugira ngo hubahirizwe inshingano, hagenzurwe ubunyangamugayo no kwemeza uri gukora ikizamini.",
      microphone: "Mikoro",
      microphoneDescription:
        "Hagamijwe kureba ubunyangamugayo ndetse ko umukandida akurikiza amabwiriza.",
      os: "Sisitemu",
      osDescription:
        "Kugira ngo  hamenyekane ko ikizamini kirakorwa neza nta makemwa.",
      browser: "Imbuga za murandasi zikoreshwa",
      browserDescription:
        "Kugira ngo humvikana neza ibikenewe bituma ikizamini gikorwa neza",
      internetDescription:
        "Ni ngombwa kugira ngo umuntu akore neza ikizamini nta bangamirwa ritewe na murandasi ",
      soundLevel: "Ingano yi ijwi",
      soundDescription:
        "Hagenzurwe ko icyumba kiri gukorerwamo gituje kandi ko amabwiriza ari kubahirizwa",
      computerToProjector:
        "Birabujijwe guhuza mudasobwa yawe na projector mu gihe cy'ikizamini.",
      proctoringConcent:
        "Igihe wemeye gutangira iki kizamini, wemeye kugenzurwa no gufatwa amashusho binyuze muri kamera ya mudasobwa yawe. Ikizamini gitangira ako nyanya amasegonda wahawe yo kwitegura akirangira. Ntabwo ushobora guhagarika cyangwa gusubira inyuma. Niba utiteguye, hita uhagarikira aha.",
      webAndMic: "Imikoreshereze ya Kamera na Mikoro",
      validId: "Usabwa kwerekana icyangombwa cyawe",
      validIdDesc:
        "Umukandida agomba kugaragaza ikarita y'indangamuntu yemewe, kandi izina yakoresheje yiyandikisha rigomba guhura n'iriri ku ndangamuntu ye.",
      webcamDesc:
        "Kamera na mikoro bigomba gukora igihe cyose uri mu kizamini, bibaye ngombwa ayo mashusho yakoreshwa mu gusuzuma imyitwarire ikemangwa.",
      detecting: "Tegereza ...",
      personDetected: "Uragaragara!",
      moreThanOne: "Murenze umwe!",
      noDetected: "Nta muntu ugaragara!",
      startExam: "Tangira ikizami!",
      noCancel: "Oya, Hagarika",
      yesStart: "yego, Tangira",
      getReady: "Itegure gutangira...",
      starting: "Tangira...",
      cellPhoneDetected:
        "Igisa na Telefone cyagaragaye muri kamera yawe. Suzuma neza ko nta gikoresho kitemewe ufite, bityo ubashe gukomeza.",
      faceNotDetected:
        "Isura yawe ntabwo igaragara. Reba neza muri kamera yawe, kugira ngo ukomeze.",
      multipleDetected:
        "Hagaragaye abantu barenze umwe muri kamera yawe. Usabwa gukora uri wenyine",
      actionRecorded: "Icyitonderwa: Ibyo ukoze twabyakiriye.",
      returnToExam: "Urasabwa kwegera kamera yawe kugirango ukomeze ikizamini.",
      error: "Erreur",
      windowMinT: "Wavuye muri FullScreen",
      windowMinD:
        "Kugabanya idirishya mu gihe ukora ikizamini birabujijwe, kandi bishobora gutera ibihano birimo gusubika ikizamini",
      unAuthorizedObject:
        "Hari ifoto y’ikintu kitemewe, igaraga muri kamera yawe.",
      onThe3rd:
        "Kwihanangirizwa birenze inshuro eshatu, bihanishwa kwirukanwa mu kizamini.",
      mouseLeftBrowserTitle: "Wavuye muri FullScreen",
      mouseLeftBrowserMessage: `Ibikorwa byawe by'isuzuma biragenzurwa.\nKuvanamo idirishya rya browser cyangwa tab muri iki gihe cy'isuzuma birabujijwe, kandi bishobora guteza ibihano, harimo no gusubika isuzuma.`,
      cameraWtitle: "Ibyitonderwa: Uruhushya rwa Kamera rurakenewe",
      cameraWmessage:
        "Kugira ngo ukomeze, nyamuneka shyiraho uburenganzira bwa kamera ufunge iri tangazo ukemeza uburenganzira bukenewe.",
      noPermissionsT: "Uburenganzira bwose ntibwemejwe neza!",
      noPermissionsD: "Suzuma niba uburenganzira bwose bukenewe bwemejwe",
      noCameraT: "banza wifate ifoto!",
      noCameraD: "Urasabwa kubanza kwifata ifoto mbere yo gukomeza.",
      accountLocked: "Uhagaritswe gukorera uyu mwanya",
      examTerminated:
        "Ntugishoboye gukomeza gukora iki Ikizamini kubera imyitwarire idahwitse!",
      sessionTerminated:
        "Wahagaritswe gukomeza gukora iki kizamini kubera imyitwarire yawe ikemangwa wakomeje kugaragaza. Uyu mwanzuro ntabwo ujuririrwa",
      loadingWait: "Tegereza gato...",
      clickToContinue: "Kanda hano, ukomeze",
      countDown: "Igihe gisigaye",
      completedQuestions: "Ibibazo birangiye",
      secondsRem: "Amasegonda asigaye",
      of: "kuri",
      saveContinue: "Bika, ukomeze",
      saveSubmit: "Bika, wohereze ikizamini",
      finished: "Byarangiye",
      welcomeBack: "Ikaze nanone!",
      welcomeBack1: "Witeguye gukomeza aho wagarukirije? Wahisemo",
      welcomeBack2: "nk’ururimi rw' ikizamini.",
      welcomeBack3: "kanda kuri ‘Komeza’ kugira ngo ukomeze.",
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
