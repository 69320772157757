import React from "react";
import { LinearProgress, Box, Typography } from "@mui/material";

const ProgressBar = ({ totalWarnings }) => {
  const maxWarnings = 4;

  // Calculate percentage
  const progress = (totalWarnings / maxWarnings) * 100;

  // Determine the color based on the percentage
  const getColor = (percentage) => {
    if (percentage <= 20) return "#4caf50"; // Green for 0-20%
    if (percentage <= 40) return "#8bc34a"; // Light Green for 21-40%
    if (percentage <= 60) return "#ffc107"; // Amber for 41-60%
    if (percentage <= 80) return "#ff9800"; // Orange for 61-80%
    if (percentage <= 90) return "#ff5722"; // Deep Orange for 81-90%
    return "#f44336"; // Red for 91-100%
  };

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: 400,
        margin: "20px auto",
        padding: "10px",
        textAlign: "center",
        backgroundColor: "#f9f9f9",
        borderRadius: "8px",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        position: "fixed",
        left: "40%",
        top: 5,
      }}
    >
      <Typography variant="body1" gutterBottom>
        Detected Warnings: {totalWarnings} of {maxWarnings}
      </Typography>
      <LinearProgress
        variant="determinate"
        value={progress}
        sx={{
          height: "10px",
          borderRadius: "5px",
          "& .MuiLinearProgress-bar": {
            backgroundColor: getColor(progress),
            transition: "background-color 0.5s ease", // Smooth color transition
          },
        }}
      />
    </Box>
  );
};

export default ProgressBar;
