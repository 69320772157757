import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React from "react";

import { connect } from "react-redux";
import FeedbackFormDialog from "../feedBackForm/FeedBackForm";

const ViewMarks = (props) => {
  const {
    message,
    setConfirmationDialog,
    loading,
    confirmationDialog,
    onYes,
    noPreFormat,
    error,
    btnMessage,
    isLoading,
    disabled,
    onWait,
    color,
    title,
    hideCloseBtn,
    icon,
    totalScore,
  } = props;

  return (
    <>
      <Dialog
        open={confirmationDialog}
        // Disable backdrop close and escape key close
        disableEscapeKeyDown
        onClose={() => {}}
        BackdropProps={{
          onClick: (e) => e.stopPropagation(), // Prevent close on backdrop click,
          style: {
            backgroundColor: "rgba(0, 0, 0, 0.6)", // Add blur effect to background
            backdropFilter: "blur(8px)",
          },
        }}
        PaperProps={{
          style: { borderRadius: "15px" }, // Add border-radius here
          width: "1000px", // Set custom width (adjust as needed)
          maxWidth: "none", // Prevent predefined maxWidth from limiting the width
        }}
      >
        <DialogTitle className={`text-${error ? "danger" : "primary"}`}>
          <span className="d-flex align-items-center">
            {!error && (
              <span className="material-icons mr-1">{icon || "error"}</span>
            )}
            {error && (
              <span className="material-icons mr-1">{icon || "warning"}</span>
            )}

            {error ? title || "Error" : title || "Psychometric Test Score %"}
          </span>
        </DialogTitle>
        <DialogContent
          className={`pb-0 text-center ${error ? "text-danger" : ""}`}
        >
          {noPreFormat ? (
            message
          ) : (
            <pre
              style={{
                fontFamily: `"Roboto","Helvetica","Arial","sans-serif"`,
                fontSize: "1rem",
                fontWeight: "500",
                lineHeight: "1.5",
                whiteSpace: "pre-wrap",
              }}
            >
              {message}
            </pre>
          )}
          <br />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                height: "180px",
                width: "180px",
                borderRadius: "50%",
                backgroundColor: totalScore >= 50 ? "green" : "red",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                fontWeight: "bolder",
                textAlign: "center",
              }}
            >
              <div>
                <div>Total Score:</div>
                <div
                  style={{
                    fontSize: "30px",
                    marginTop: "-6px",
                    fontWeight: "bolder",
                  }}
                >
                  {parseFloat(totalScore).toFixed(1)}%
                </div>
              </div>
            </div>
          </div>
          <br />
          <br />
          <br />
        </DialogContent>
        <DialogActions className="d-flex justify-content-center pb-4">
          <FeedbackFormDialog />
          <Button
            color={color || "primary"}
            variant="contained"
            onClick={onYes}
            className="ml-2 px-4 rounded-pill"
          >
            {loading || onWait ? "Wait..." : btnMessage || "Confirm"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const mapStateToProps = ({ loading, onWait }) => {
  return { loading, onWait };
};
export default connect(mapStateToProps)(ViewMarks);
