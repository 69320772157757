import { useEffect, useState } from "react";
import axios from "axios";
import CountdownTimer from "../helper/CountdownTimer";
import { useTranslation } from "react-i18next";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from "@mui/material";
import "./CustomPsyCss.css";
import ProctoringContainer from "../ProctoringContainer/ProctoringContainer";
import HandleMatching from "./handleQuestionTypes/HandleMatching";
import HandleFillMissing from "./handleQuestionTypes/HandleFillMissing";
import { submitProctoringTest } from "../helper/FC_SubmitTheTest";
import { saveQuestionAnswer } from "../helper/FC_SubmitAnswer";
import ViewMarks from "./ViewMarks/ViewMarks";
import { checkApplicantSessionStatus } from "../helper/FC_VerifyApplicantStatus";
import AlertMessage from "./AlertMessage/AlertMessage";
import ConfirmationDialog from "./ConfirmationDialog/ConfirmationDialog";
import sessionLog from "../helper/ApiRequests";

const Test = ({ onSubmitTest, onCancel, advertisement }) => {
  const [testData, setTestData] = useState([]);
  const [isDone, setIsDone] = useState([]);
  const [error, setError] = useState("");
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedOption, setSelectedOption] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [advertisementId, setAdvertisementId] = useState(null);
  const [existingTestData, setExistingTestData] = useState(null);
  const { t, i18n } = useTranslation();
  const [selectedQuestionScore, setSelectedQuestionScore] = useState(null);
  const [totalScore, setTotalScore] = useState(0);
  const [totalQuestionsMarks, setTotalQuestionsMarks] = useState(0);
  const [isTestSubmitted, setTestSubmitted] = useState(false);
  const [isTestLocked, setIsTestLocked] = useState(false); // If the test is locked on you!
  const [errorMessage, setErrorMessage] = useState(null);
  const [emptyAnswerWarning, setEmptyAnswerWarning] = useState(null);
  const [isTestStarted, setIsTestStarted] = useState(false);

  const getaTest = async (
    language,
    advertisementId,
    existingTestDataDetails
  ) => {
    setLoading(true);
    setSelectedLanguage(language);

    let preferredLanguageId = null;

    switch (language) {
      case "English":
        preferredLanguageId = 1;
        i18n.changeLanguage("eng");
        break;
      case "Francais":
        preferredLanguageId = 2;
        i18n.changeLanguage("fr");
        break;
      case "Kinyarwanda":
        preferredLanguageId = 3;
        i18n.changeLanguage("kiny");
        break;
      default:
        preferredLanguageId = 1;
        i18n.changeLanguage("eng");
    }

    let tempData = {
      advertisementId: advertisementId,
      preferredLanguageId: preferredLanguageId,
      psySession:
        existingTestDataDetails === null || existingTestDataDetails.length === 0
          ? ""
          : existingTestDataDetails[0].id,
    };

    try {
      const { data: response } = await axios.post(
        "/api/psychometric-test/startthetest",
        tempData
      );

      // Set Total Questions Score
      const totalScoreQuestions = response.reduce(
        (acc, itm) => acc + itm.score,
        0
      );
      setTotalQuestionsMarks(totalScoreQuestions);

      // Set already answered questions total score
      const totalAnsweredQuestionsScoreArr = response.filter(
        (itm) => itm.questionScore !== undefined && itm.questionScore !== null
      );
      setTotalScore(
        existingTestDataDetails === null || existingTestDataDetails.length === 0
          ? 0
          : totalAnsweredQuestionsScoreArr.reduce(
              (acc, itm) => acc + itm.questionScore,
              0
            )
      );

      if (response) {
        console.log(response);
        // Get it sorted by questionScore
        const responseTemp = response.sort((a, b) => {
          // Check if a or b has questionScore as undefined or null
          const aHasScore =
            a.questionScore !== undefined && a.questionScore !== null;
          const bHasScore =
            b.questionScore !== undefined && b.questionScore !== null;

          // Sort to prioritize items with questionScore defined
          if (aHasScore && !bHasScore) return -1; // a comes before b
          if (!aHasScore && bHasScore) return 1; // b comes before a
          return 0; // No change in order if both are same
        });

        const dataToKeep = responseTemp.map((item, i) => {
          if (
            item.questionScore &&
            item.questionScore !== undefined &&
            item.questionScore !== null
          ) {
            setIsDone((prev) => [...prev, item.id]);
          }
          return {
            ...item,
            i: i + 1,
          };
        });
        setTestData(dataToKeep);
        console.log(
          "Questios valid-----------------: ",
          dataToKeep.filter(
            (itm) =>
              itm.questionScore === undefined || itm.questionScore === null
          )
        );
        setSelectedQuestion(
          dataToKeep.filter(
            (itm) =>
              itm.questionScore === undefined || itm.questionScore === null
          )[0] || null
        );
        setError("");
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
      setError("Failed to load test data");
      setLoading(false);
    }
  };

  const setaTest = async (
    language,
    advertisementId,
    existingTestDataDetails
  ) => {
    setLoading(true);
    setSelectedLanguage(language);

    let preferredLanguageId = null;

    switch (language) {
      case "English":
        preferredLanguageId = 1;
        i18n.changeLanguage("eng");
        break;
      case "Francais":
        preferredLanguageId = 2;
        i18n.changeLanguage("fr");
        break;
      case "Kinyarwanda":
        preferredLanguageId = 3;
        i18n.changeLanguage("kiny");
        break;
      default:
        preferredLanguageId = 1;
        i18n.changeLanguage("eng");
    }

    let tempData = {
      advertisementId: advertisementId,
      preferredLanguageId: preferredLanguageId,
      psySession:
        existingTestDataDetails === null || existingTestDataDetails.length === 0
          ? ""
          : existingTestDataDetails[0].id,
    };

    try {
      // const { data: response } = await axios.post(
      //   "/api/psychometric-test/startthetest",
      //   tempData
      // );

      // // Set Total Questions Score
      // const totalScoreQuestions = response.reduce(
      //   (acc, itm) => acc + itm.score,
      //   0
      // );
      // setTotalQuestionsMarks(totalScoreQuestions);

      // // Set already answered questions total score
      // const totalAnsweredQuestionsScoreArr = response.filter(
      //   (itm) => itm.questionScore !== undefined && itm.questionScore !== null
      // );
      // setTotalScore(
      //   existingTestDataDetails === null || existingTestDataDetails.length === 0
      //     ? 0
      //     : totalAnsweredQuestionsScoreArr.reduce(
      //         (acc, itm) => acc + itm.questionScore,
      //         0
      //       )
      // );

      // if (response) {
      //   console.log(response);
      //   // Get it sorted by questionScore
      //   const responseTemp = response.sort((a, b) => {
      //     // Check if a or b has questionScore as undefined or null
      //     const aHasScore =
      //       a.questionScore !== undefined && a.questionScore !== null;
      //     const bHasScore =
      //       b.questionScore !== undefined && b.questionScore !== null;

      //     // Sort to prioritize items with questionScore defined
      //     if (aHasScore && !bHasScore) return -1; // a comes before b
      //     if (!aHasScore && bHasScore) return 1; // b comes before a
      //     return 0; // No change in order if both are same
      //   });

      //   const dataToKeep = responseTemp.map((item, i) => {
      //     if (
      //       item.questionScore &&
      //       item.questionScore !== undefined &&
      //       item.questionScore !== null
      //     ) {
      //       setIsDone((prev) => [...prev, item.id]);
      //     }
      //     return {
      //       ...item,
      //       i: i + 1,
      //     };
      //   });
      //   setTestData(dataToKeep);
      //   console.log(
      //     "Questios valid-----------------: ",
      //     dataToKeep.filter(
      //       (itm) =>
      //         itm.questionScore === undefined || itm.questionScore === null
      //     )
      //   );
      //   setSelectedQuestion(
      //     dataToKeep.filter(
      //       (itm) =>
      //         itm.questionScore === undefined || itm.questionScore === null
      //     )[0] || null
      //   );
      //   setError("");
      //   setLoading(false);
      // }
    } catch (err) {
      console.log(err);
      setError("Failed to load test data");
      setLoading(false);
    }
  };

  // Handle multiple choice questions
  const submitMultipleChoiceData = (
    selectedQuestionToAnswer,
    optionItem,
    testDataToAnswer,
    score
  ) => {
    setLoading(true);
    console.log("====================================");
    console.log("Handling multiple choice question.......................");
    console.log("====================================");
    const data = selectedQuestionToAnswer;
    if (data !== undefined && data !== null) {
      const selectedOptionItemTest = data.options.find(
        (itm) => itm.id.toString() === optionItem.toString()
      );
      const selectedOptionItem =
        selectedOptionItemTest === undefined
          ? {
              id: "none",
              optionNumber: "",
            }
          : selectedOptionItemTest;
      if (!selectedOptionItem) {
        setLoading(false);
        return;
      }
      // Making an API call to mark the question
      try {
        const theCorrectOption = data.options.find(
          (itm) => itm.isCorrect === true
        )?.id;
        const markedScore =
          score || selectedOptionItem?.id === theCorrectOption
            ? selectedQuestionToAnswer.score
            : 0;

        setTotalScore((prevScore) => prevScore + markedScore);

        // Making an API call to mark the question

        saveQuestionAnswer(
          data.testSessionId,
          selectedQuestionToAnswer,
          selectedOptionItem,
          markedScore,
          (status, res) => {
            if (res === null) {
              setLoading(true);
            }
            if (res !== null && status === false && res.status === "success") {
              // After submitting the data, then
              setIsDone((prev) => [...prev, selectedQuestionToAnswer.id]);

              const nextItem =
                testDataToAnswer[selectedQuestionToAnswer.i] || null;

              if (nextItem === null) {
                return onSubmitFinalTest(testDataToAnswer);
              } else {
                setSelectedQuestion(null);
                setSelectedQuestionScore(null);
                setTimeout(() => setSelectedQuestion(nextItem), 10);
              }
              setLoading(false);
              setEmptyAnswerWarning(null);
              return;
            }

            if (res !== null && status === false && res.status === "error") {
              setLoading(false);
              setEmptyAnswerWarning(null);
              setErrorMessage({
                title: "Please Contact The Admin",
                description: res.message,
              });
              return;
            }
          }
        );
      } catch (err) {
        setEmptyAnswerWarning(null);
        setLoading(false);
        console.error("Error processing the question:", err);
        return;
      }
      return;
    } else {
      setLoading(false);
      setEmptyAnswerWarning(null);
      setErrorMessage({
        title: "Validation Error",
        description: "No question selected",
      });
    }
  };

  // Handle matching questions
  const submitMatchingData = (
    selectedQuestionToAnswer,
    optionItem,
    testDataToAnswer,
    score
  ) => {
    console.log("====================================");
    console.log("Handling matching question..........");
    console.log("====================================");
    const data = selectedQuestionToAnswer;
    if (data !== undefined && data !== null) {
      // Making an API call to mark the question
      try {
        setTotalScore((prevScore) => prevScore + (score || 0));

        // const testSessionId = advertisement.id;

        // Making an API call to mark the question
        saveQuestionAnswer(
          data.testSessionId,
          selectedQuestionToAnswer,
          optionItem,
          score || 0,
          (status, res) => {
            if (res === null) {
              setLoading(true);
            }
            if (res !== null && status === false && res.status === "success") {
              // After submitting the data, then
              setIsDone((prev) => [...prev, selectedQuestionToAnswer.id]);

              const nextItem =
                testDataToAnswer[selectedQuestionToAnswer.i] || null;

              if (nextItem === null) {
                return onSubmitFinalTest(testDataToAnswer);
              } else {
                setSelectedQuestion(null);
                setSelectedQuestionScore(null);
                setTimeout(() => setSelectedQuestion(nextItem), 10);
              }
              setEmptyAnswerWarning(null);
              setLoading(false);
              return;
            }

            if (res !== null && status === false && res.status === "error") {
              setEmptyAnswerWarning(null);
              setLoading(false);
              setErrorMessage({
                title: "Please Contact The Admin",
                description: res.message,
              });
              return;
            }
          }
        );
      } catch (err) {
        setEmptyAnswerWarning(null);
        setLoading(false);
        console.error("Error processing the question:", err);
        return;
      }
      return;
    } else {
      setEmptyAnswerWarning(null);
      setErrorMessage({
        title: "Validation Error",
        description: "No question selected",
      });
    }
  };

  const saveAnswer = async (
    testDataToAnswer,
    selectedQuestionToAnswer,
    optionItem,
    score,
    isCountDown
  ) => {
    if (testDataToAnswer) {
      if (!selectedQuestionToAnswer)
        return setErrorMessage({
          title: "Validation Error",
          description: "No question selected 1",
        });
      // if (
      //   selectedQuestionToAnswer?.id ===
      //   testDataToAnswer[testDataToAnswer.length - 1]?.id
      // ) {
      //   if (
      //     window.confirm("Are you sure do you want to submit the test?") ===
      //     false
      //   ) {
      //     return;
      //   }
      // }
      setLoading(true);

      const isTextOption = typeof optionItem === "string";
      if (isTextOption) {
        // Check if there is no answer
        const selectedOptionItemTest = selectedQuestionToAnswer.options.find(
          (itm) => itm.id.toString() === optionItem.toString()
        );
        if (
          selectedOptionItemTest === undefined &&
          emptyAnswerWarning === null &&
          isCountDown === false
        ) {
          setEmptyAnswerWarning({
            testDataToAnswer,
            selectedQuestionToAnswer,
            optionItem,
            score,
          });
          setLoading(false);
          return;
        }
        // Handle multiple choice, true/false, and fill-in-the-blank questions
        submitMultipleChoiceData(
          selectedQuestionToAnswer,
          optionItem,
          testDataToAnswer,
          score
        );
      } else {
        // Check if user answered matching question for all options
        if (
          (optionItem.length === 0 ||
            optionItem.length < selectedQuestionToAnswer.options.length) &&
          emptyAnswerWarning === null &&
          isCountDown === false
        ) {
          setEmptyAnswerWarning({
            testDataToAnswer,
            selectedQuestionToAnswer,
            optionItem,
            score,
          });
          setLoading(false);
          return;
        }
        // Handle matching questions
        submitMatchingData(
          selectedQuestionToAnswer,
          optionItem,
          testDataToAnswer,
          score
        );
      }
    }
    return;
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const isRadioButtonSelected = (optionId) => {
    return selectedQuestion?.answer === optionId || selectedOption === optionId;
  };

  const handleFinish = async (
    testDataTest,
    selectedQuestionTest,
    selectedOptionItemSelected,
    resultScore
  ) => {
    if (testDataTest && selectedQuestionTest) {
      console.log("Countdown finished!");
      await saveAnswer(
        testDataTest,
        selectedQuestionTest,
        selectedOptionItemSelected,
        resultScore,
        true
      );
    }
  };

  const handleLanguageChange = async (language, candidateImage, setLoadingTest) => {
    setLoading(true);
    setLoadingTest(true);
    try {
      await getaTest(language, advertisement.id, existingTestData);
      // Start the session
      sessionLog(
        advertisement.id,
        "SESSION START",
        "THE USER STARTED THE TEST",
        candidateImage
      );

      setLoadingTest(false);
    } catch (error) {
      setErrorMessage({
        title: "Validation Error",
        description: "Failed to load the test!",
      });
    }
  };

  const handleSetLanguage = async (language) => {
    setLoading(true);
    try {
      const res = await checkApplicantSessionStatus(
        advertisement.id,
        language,
        (res) => {
          console.log({ "Candidate checking....: ": res });
          if (res !== null) {
            if (res.applicant_session_blocked === false) {
              // get the test according to the language chosen
              setIsTestLocked(false);
              setAdvertisementId(advertisement.id);
              setaTest(language, advertisement.id, existingTestData);
              //setLoadingTest(false);
              setLoading(false);
            } else {
              setIsTestLocked(true);
              setLoading(false);
              //setLoadingTest(false);
            }
          }
        }
      );
    } catch (error) {
      setErrorMessage({
        title: "Validation Error",
        description: "Failed to load the test!",
      });
    }
  };


  // On last question, submit the test marks before going to total marks

  const onSubmitFinalTest = (testQuestionsData) => {
    // Set final total score

    // Calculate the total score for the test
    const totalScoreQuestions = totalQuestionsMarks;
    // Save the test data to the database
    submitProctoringTest(
      (returnTotalScore() * 100) / totalScoreQuestions,
      testQuestionsData ? testQuestionsData.length : 0,
      advertisement,
      (res) => {
        if (res === null) {
          setLoading(true);
        }
        if (res !== null && res === true) {
          // After test submission, go to total marks
          setSelectedQuestion(null);
          setSelectedOption("");
          // onSubmitTest(testQuestionsData ? testQuestionsData.length : 0);
          setTestSubmitted(true);
          setIsTestStarted(false);
        } else {
          setLoading(false);
          setError("Failed to submit test!");
        }
      }
    );
  };
  useEffect(() => {
    const handlePrevent = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to document
    document.addEventListener("copy", handlePrevent);
    document.addEventListener("cut", handlePrevent);
    document.addEventListener("paste", handlePrevent);

    // Cleanup event listeners when component unmounts
    return () => {
      document.removeEventListener("copy", handlePrevent);
      document.removeEventListener("cut", handlePrevent);
      document.removeEventListener("paste", handlePrevent);
    };
  }, []);

  // Get total score
  const returnTotalScore = () => {
    return totalScore;
  };

  return (
    <ProctoringContainer
      selectedLanguage={selectedLanguage}
      handleSetLanguage={(language, loadTest) =>
        handleSetLanguage(language, loadTest)
      }
      handleLanguageChange={(language, candidateImage, loadTest) =>
        handleLanguageChange(language, candidateImage, loadTest)
      }
      onCancel={onCancel}
      isTestStarted={isTestStarted}
      setIsTestStarted={(value) => setIsTestStarted(value)}
      advertisement={advertisement}
      setExistingTestData={(existingData) => {
        setExistingTestData(existingData);
        if (existingData.length > 0) {
          
          // getaTest(
          //   existingData[0].preferredLanguageId.toString() === "3"
          //     ? "Kinyarwanda"
          //     : existingData[0].preferredLanguageId.toString() === "2"
          //     ? "Francais"
          //     : "English",
          //   advertisement.id,
          //   existingData
          // );
        }
      }}
      existingTestData={existingTestData}
      totalQuestions={testData ? testData.length : 0}
      totalScore={returnTotalScore()}
      isTestLocked={isTestLocked}
      setIsTestLocked={(value) => setIsTestLocked(value)}
      istTestSubmitted={isTestSubmitted}
    >
      <div>
        <div
          className="col-12 p-0 pb-2 pt-2 pl-3 bg-white border-bottom"
          style={{ paddingTop: "16px" }}
        >
          <div className="d-flex align-items-center justify-content-between">
            <div className="fs-2 text-primary">
              <b>Psychometric Test</b> | {advertisement.positionName}
            </div>
            <div className="d-flex justify-content-end">
              <div className="pr-2 mr-2 border-right">
                <div style={{ fontSize: "12px" }}>{t("countDown")}</div>
                {selectedQuestion && loading === false && (
                  <div style={{ fontWeight: "bolder" }}>
                    <CountdownTimer
                      duration={selectedQuestion.questionTimeInSec}
                      onFinish={() =>
                        handleFinish(
                          testData,
                          selectedQuestion,
                          selectedOption,
                          selectedQuestionScore
                        )
                      }
                    />
                  </div>
                )}
              </div>
              <div className="pr-3">
                <div style={{ fontSize: "12px" }}>
                  {t("completedQuestions")}
                </div>
                <div style={{ fontWeight: "bolder" }}>
                  {selectedQuestion?.i ?? 0} {t("of")} {testData?.length}
                </div>
              </div>
            </div>
          </div>
        </div>

        {loading === true || selectedQuestion === null ? (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: "100%", minHeight: "400px", width: "100%" }}
          >
            <h2>{t("loadingWait")}</h2>
          </div>
        ) : isTestSubmitted === true ? (
          <div style={{ padding: "20px" }}>
            <div>
              Please, Click on the following <b>Confirm</b> button to Continue
            </div>
            <div style={{ paddingTop: "10px" }}>
              <Button
                onClick={() => (window.location.href = "/")}
                variant="contained"
                color="primary"
              >
                {t("clickToContinue")}
              </Button>
            </div>
          </div>
        ) : (
          <div
            className="col-12 bg-light"
            style={{ padding: "80px", paddingBottom: "100px" }}
          >
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() !== "3" && (
                <>
                  <div className="fw-bold mb-3" style={{ fontSize: "18px" }}>
                    Q{selectedQuestion?.i}.{" "}
                    {selectedLanguage === "English"
                      ? selectedQuestion.en
                      : selectedLanguage === "Francais"
                      ? selectedQuestion.fr
                      : selectedQuestion.kn}{" "}
                  </div>
                  <div className="mb-2" style={{ fontSize: "15px" }}>
                    {t("selectOne")}
                  </div>
                </>
              )}
            {/* Handle Fill Missing */}
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() === "3" && (
                <HandleFillMissing
                  selectedQuestion={selectedQuestion}
                  selectedLanguage={selectedLanguage}
                  submitQuestionAnswer={(selectedOption, calcScore) =>
                    saveAnswer(
                      testData,
                      selectedQuestion,
                      selectedOption,
                      calcScore,
                      false
                    )
                  }
                  isLastQuestion={
                    testData !== null &&
                    testData.length > 0 &&
                    testData[selectedQuestion.i] === undefined
                  }
                  onChangeData={(data) => setSelectedOption(data.id)}
                />
              )}
            {/* Matching question type */}
            {selectedQuestion &&
              selectedQuestion.questionTypeId.toString() === "5" && (
                <HandleMatching
                  selectedQuestion={selectedQuestion}
                  selectedLanguage={selectedLanguage}
                  submitQuestionAnswer={(calcScore, selectedOption) => {
                    saveAnswer(
                      testData,
                      selectedQuestion,
                      selectedOption,
                      calcScore,
                      false
                    );
                  }}
                  isLastQuestion={
                    testData !== null &&
                    testData.length > 0 &&
                    testData[selectedQuestion.i] === undefined
                  }
                  onChangeData={(selectedOptionResult, calcScore) => {
                    setSelectedOption(selectedOptionResult);
                    setSelectedQuestionScore(calcScore);
                  }}
                />
              )}
            {/* Multiple choice */}
            {selectedQuestion &&
              (selectedQuestion.questionTypeId.toString() === "1" ||
                selectedQuestion.questionTypeId.toString() === "4") && (
                <div className="pl-4 mb-4">
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      name="radio-buttons-group"
                      value={selectedOption}
                      onChange={handleOptionChange}
                    >
                      {selectedQuestion.options.map((option, i) => (
                        <FormControlLabel
                          key={i + 1}
                          value={option.id}
                          control={<Radio />}
                          label={`${option.optionNumber}) ${
                            selectedLanguage === "English"
                              ? option.en
                              : selectedLanguage === "Francais"
                              ? option.fr
                              : option.kn
                          }`}
                          checked={isRadioButtonSelected(option.id)}
                        />
                      ))}
                    </RadioGroup>
                  </FormControl>
                </div>
              )}

            {selectedQuestion &&
              (selectedQuestion.questionTypeId.toString() === "1" ||
                selectedQuestion.questionTypeId.toString() === "4") && (
                <div
                  className="mt-2 bg-primary pl-4 pr-4 pt-2 pb-2 rounded-pill text-white"
                  style={{ width: "max-content", cursor: "pointer" }}
                  onClick={() =>
                    testData &&
                    selectedQuestion !== null &&
                    saveAnswer(
                      testData,
                      selectedQuestion,
                      selectedOption,
                      null,
                      false
                    )
                  }
                >
                  {testData !== null &&
                  testData.length > 0 &&
                  testData[selectedQuestion.i] !== undefined
                    ? t("saveContinue")
                    : t("saveSubmit")}
                </div>
              )}
          </div>
        )}

        {/* Display error */}
        {errorMessage !== null && errorMessage.title && (
          <div style={{ paddingLeft: "10px", paddingRight: "10px" }}>
            <AlertMessage
              type={"error"}
              errorMessageTitle={errorMessage.title}
              errorMessageDescription={errorMessage.description}
              onClose={() => setErrorMessage(null)}
            />
          </div>
        )}
      </div>

      {/* View Marks total score */}
      {isTestSubmitted === true && (
        <ViewMarks
          disabled={false}
          btnMessage={
            loading === true
              ? "Please wait..."
              : (returnTotalScore() * 100) / totalQuestionsMarks >= 50
              ? "Click to continue"
              : "Yes Continue"
          }
          confirmationDialog={() => {}}
          message={
            (returnTotalScore() * 100) / totalQuestionsMarks >= 50
              ? `PSYCHOMETRIC TEST SUBMITTED SUCCESSFULLY`
              : `YOU FAILED THE PSYCHOMETRIC TEST`
          }
          totalScore={parseFloat(
            (returnTotalScore() * 100) / totalQuestionsMarks
          ).toFixed(1)}
          setConfirmationDialog={() => {}}
          onYes={() => {
            window.location.href = "/applicant/applications";
          }}
        />
      )}
      <ConfirmationDialog
        title={"Empty Answer Warning"}
        description={
          "Are you sure you want to submit without answering the question?"
        }
        isOpen={emptyAnswerWarning === null ? false : true}
        onClose={() => {
          setEmptyAnswerWarning(null);
          setLoading(false);
        }}
        onConfirm={() => {
          console.log("emptyAnswerWarning: ", emptyAnswerWarning);
          saveAnswer(
            emptyAnswerWarning.testDataToAnswer,
            emptyAnswerWarning.selectedQuestionToAnswer,
            emptyAnswerWarning.optionItem,
            emptyAnswerWarning.score,
            false
          );
        }}
      />
    </ProctoringContainer>
  );
};

export default Test;

// ! Display a confirmation before submitting a question without answering it
