import React, { Fragment, useEffect, useState } from "react";
import { Button, Typography } from "@mui/material";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
} from "@mui/material";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import { styled } from "@mui/system";
import { t } from "i18next";

const BlurredOverlay = styled("div")({
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backdropFilter: "blur(8px)",
  backgroundColor: "transparent",
  zIndex: 1000,
});

const StyledDialog = styled(Dialog)(({ theme }) => ({
  display: "flex",
  alignItems: "center", // Centers the dialog vertically
  justifyContent: "center", // Centers the dialog horizontally
  "& .MuiPaper-root": {
    borderRadius: "20px",
    padding: theme.spacing(2),
    boxShadow: `0 8px 20px rgba(0, 0, 0, 0.2)`,
    backgroundColor: "#e8d4b7", // Ensures the dialog background is distinct
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column", // Ensures the children are in a column layout
  },
}));

const FullScreenContainer = ({
  children,
  isFullScreen,
  setIsFullScreen,
  currentStep,
  onSetWarningDetection, // LEAVE_BROWSER, COPY_TEXT, PASTE_TEXT
  warningDetection,
}) => {
  const enterFullScreen = () => {
    const elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen().catch((err) => {
        console.error("Error attempting to enable full-screen mode:", err);
      });
    }
  };

  const handleFullscreenChange = () => {
    if (document.fullscreenElement) {
      setIsFullScreen(true);
    } else {
      setIsFullScreen(false);
      enterFullScreen();
    }
  };

  const handleVisibilityChange = () => {
    if (document.visibilityState === "hidden") {
      enterFullScreen();
    }
    if (document.hidden) {
      onSetWarningDetection({
        type: t("windowMinT"),
        message: t("windowMinD"),
      });
    }
  };

  const disableShortcuts = (event) => {
    if (event.ctrlKey || event.altKey || event.shiftKey || event.metaKey) {
      onSetWarningDetection({
        type: "SHORTCUT_KEYS",
        message:
          "You are not allowed to click on shortcut keys. or you are going to lose your test",
      });
      event.preventDefault();
    }
  };

  useEffect(() => {
    document.addEventListener("copy", (event) => {
      event.preventDefault();
      onSetWarningDetection({
        type: "COPY_TEXT",
        message:
          "You are not allowed to copy a text. or you are going to lose your test",
      });
    });

    document.addEventListener("paste", (event) => {
      event.preventDefault();
      onSetWarningDetection({
        type: "PASTE_TEXT",
        message:
          "You are not allowed to paste a text. or you are going to lose your test",
      });
    });

    window.addEventListener("blur", () => {
      return onSetWarningDetection({
        type: "LEAVE_BROWSER",
        message:
          "You are not allowed to leave the browser window. or you are going to lose your test",
      });
    });

    // prevent the right-click
    document.addEventListener("contextmenu", (event) => {
      event.preventDefault();
      onSetWarningDetection({
        type: "RIGHT_CLICK",
        message:
          "You are not allowed to right click. or you are going to lose your test",
      });
    });

    document.addEventListener("keydown", (event) => {
      event.preventDefault(); // Prevents the default action for all keys
      // onSetWarningDetection({
      //   type: "PRESS_KEY",
      //   message:
      //     "You are not allowed to leave the browser window. or you are going to lose your test",
      // });
    });

    document.addEventListener("keydown", disableShortcuts);
    // -----------------------------
    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("visibilitychange", handleVisibilityChange);

    currentStep > 4 && enterFullScreen();

    // Mouse leaving the screen detection
    const handleMouseLeave = (event) => {
      if (event.clientY <= 0) {
        // Mouse left the browser at the top
        onSetWarningDetection({
          type: t("mouseLeftBrowserTitle"),
          message: t("mouseLeftBrowserMessage"),
        });
      } else if (event.clientX <= 0) {
        // Mouse left the browser on the left side
        onSetWarningDetection({
          type: t("mouseLeftBrowserTitle"),
          message: t("mouseLeftBrowserMessage"),
        });
      } else if (event.clientX >= window.innerWidth) {
        // Mouse left the browser on the right side
        onSetWarningDetection({
          type: t("mouseLeftBrowserTitle"),
          message: t("mouseLeftBrowserMessage"),
        });
      } else if (event.clientY >= window.innerHeight) {
        // Mouse left the browser at the bottom
        onSetWarningDetection({
          type: t("mouseLeftBrowserTitle"),
          message: t("mouseLeftBrowserMessage"),
        });
      }
    };

    // Add event listeners
    document.addEventListener("visibilitychange", handleVisibilityChange);
    document.addEventListener("mouseout", handleMouseLeave);

    // Cleanup on unmount
    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      document.removeEventListener("mouseout", handleMouseLeave);
    };
  }, []);

  return (
    <Fragment>
      {children}
      {/* Handle the warning detection */}
      {warningDetection !== null && (
        <>
          <BlurredOverlay />
          <StyledDialog
            open={true}
            onClose={() => onSetWarningDetection(null)}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>
              <IconButton color="warning" sx={{ mr: 1 }}>
                <FullscreenExitIcon style={{ fontSize: "100px" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h5" fontWeight={"bold"} gutterBottom>
                {warningDetection.type}
              </Typography>
              <Typography variant="body1" gutterBottom>
                {warningDetection.message}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => onSetWarningDetection(null)}
                color="warning"
                variant="contained"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderRadius: "20px",
                  width: "100%",
                }}
              >
                Click to continue
              </Button>
            </DialogActions>
          </StyledDialog>
        </>
      )}
      {/* ---------------------------------------------------- */}
      {currentStep > 4 && (
        <>
          {!isFullScreen && <BlurredOverlay />}
          <StyledDialog
            open={!isFullScreen}
            onClose={() => {}}
            maxWidth="xs"
            fullWidth
          >
            <DialogTitle>
              <IconButton color="warning" sx={{ mr: 1 }}>
                <FullscreenExitIcon style={{ fontSize: "100px" }} />
              </IconButton>
            </DialogTitle>
            <DialogContent>
              <Typography variant="h5" fontWeight={"bold"} gutterBottom>
                Full-Screen Mode Required
              </Typography>
              <Typography variant="body1" gutterBottom>
                Please enter full-screen mode to access the exam. You are not
                allowed to proceed without enabling full-screen.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={enterFullScreen}
                color="warning"
                variant="contained"
                style={{
                  paddingLeft: "30px",
                  paddingRight: "30px",
                  borderRadius: "20px",
                  width: "100%",
                }}
              >
                Enter Full Screen
              </Button>
            </DialogActions>
          </StyledDialog>
        </>
      )}
    </Fragment>
  );
};

export default FullScreenContainer;
