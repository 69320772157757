import { IconButton, Typography } from "@mui/material";
import React, { useState } from "react";
import Announcements from "./Announcements";

const RightSideContent = () => {
  return (
    <>
      <div className="d-none d-md-block mt-2">
        <Announcements expanded={true} />
      </div>
      <div className="d-block d-md-none  mt-2">
        <Announcements expanded={false} />
      </div>

      {/* <div className="d-none d-md-block mt-2">
        <ApplicationGuidelines expanded={true} />
      </div>
      <div className="d-block d-md-none  mt-2">
        <ApplicationGuidelines expanded={false} />
      </div> */}
    </>
  );
};

export default RightSideContent;
