import React, { useState, useEffect } from "react";
import { t } from "i18next";

const CountdownTimer = ({ duration, onFinish }) => {
  const [timeLeft, setTimeLeft] = useState(duration);

  useEffect(() => {
    if (timeLeft <= 0) {
      if (onFinish) {
        onFinish();
      }
      return;
    }

    const timer = setInterval(() => {
      setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft, onFinish]);

  return (
    <div id="countdown">
      {timeLeft <= 0 ? t("finished") : `${timeLeft} ${t('secondsRem')}`}
    </div>
  );
};

export default CountdownTimer;
