import React, { useState } from "react";
import { Grid, Autocomplete, TextField, Chip } from "@mui/material";

const HandleMatching = ({
  selectedQuestion,
  selectedLanguage,
  submitQuestionAnswer,
  isLastQuestion,
  onChangeData,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(
    selectedQuestion.options.map((item) => ({
      optionNumber: item.optionNumber,
      matchEn: "", // Initial value
      matchFr: "", // Initial value
      matchKn: "", // Initial value
    }))
  );

  const selectedValueOption = (optionNumber) => {
    const selected = selectedOptions.find(
      (itm) => itm.optionNumber === optionNumber
    );
    return selected === undefined
      ? {
          optionNumber: optionNumber,
          matchEn: "",
          matchFr: "",
          matchKn: "",
        }
      : selected;
  };

  const getMatchesList = () => {
    const response = [];

    for (const item of selectedQuestion.options) {
      // Check if the match is already in the response array
      const match = response.find(
        (option) =>
          option.matchEn === item.matchEn && option.matchFr === item.matchFr
      );
      // If not, add it to the response array
      if (match === undefined) {
        response.push({
          matchEn: item.matchEn,
          matchFr: item.matchFr,
          matchKn: item.matchKn,
        });
      }
    }

    // Fisher-Yates shuffle
    for (let i = response.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [response[i], response[j]] = [response[j], response[i]]; // Swap elements
    }

    return response;
  };

  const handleChange = (event, newValueObj, optionNumber) => {
    // Update the state with the new selected options
    const newGeneratedOptionsResult = [
      ...selectedOptions.filter((itm) => itm.optionNumber !== optionNumber),
      {
        optionNumber: optionNumber,
        matchEn: newValueObj.matchEn,
        matchFr: newValueObj.matchFr,
        matchKn: newValueObj.matchKn,
      },
    ];
    setSelectedOptions(newGeneratedOptionsResult);
    // Update the state with the new selected options
    onChangeData(
      newGeneratedOptionsResult,
      hasMarks() === true ? selectedQuestion.score : 0
    );
  };

  const hasMarks = () => {
    var hasMarks = true;
    for (const test of selectedOptions) {
      // Check if the match is already in the response array
      const theCorrectOption = selectedQuestion.options.find(
        (itm) => itm.optionNumber === test.optionNumber
      );
      if (
        theCorrectOption === undefined ||
        theCorrectOption.matchEn !== test.matchEn ||
        theCorrectOption.matchFr !== test.matchFr ||
        theCorrectOption.matchKn !== test.matchKn
      ) {
        hasMarks = false;
        break;
      }
    }
    return hasMarks;
  };
  return (
    <div>
      <div className="pl-4 mb-4">
        <div>
          {selectedQuestion.options.map((option, i) => (
            <Grid
              container
              spacing={2}
              justifyContent="space-between"
              key={i + 1}
              style={{
                marginTop: "10px",
                paddingTop: "0px",
                paddingBottom: "15px",
                paddingRight: "15px",
                borderRadius: 5,
                backgroundColor: "white",
              }}
            >
              <Grid item xs={12} md={6}>
                <div style={{ height: "100%" }}>{`${option.optionNumber}) ${
                  selectedLanguage === "English"
                    ? option.en
                    : selectedLanguage === "Francais"
                    ? option.fr
                    : option.kn
                }`}</div>
              </Grid>
              <Grid item xs={12} md={6}>
                <Autocomplete
                  multiple={false}
                  options={getMatchesList()}
                  getOptionLabel={(option) =>
                    selectedLanguage === "English"
                      ? option.matchEn
                      : selectedLanguage === "Francais"
                      ? option.matchFr
                      : option.matchKn
                  }
                  value={{
                    optionNumber: option.optionNumber,
                    matchEn: selectedValueOption(option.optionNumber)?.matchEn,
                    matchFr: selectedValueOption(option.optionNumber)?.matchFr,
                    matchKn: selectedValueOption(option.optionNumber)?.matchKn,
                  }}
                  onChange={(e, data) =>
                    handleChange(e, data, option.optionNumber)
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Select Option"
                      variant="outlined"
                      placeholder="Search..."
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        label={
                          selectedLanguage === "English"
                            ? option.matchEn
                            : selectedLanguage === "Francais"
                            ? option.matchFr
                            : option.matchKn
                        }
                        {...getTagProps({ index })}
                        key={index}
                      />
                    ))
                  }
                />
              </Grid>
            </Grid>
          ))}
        </div>
      </div>
      <div
        className="mt-2 bg-primary pl-4 pr-4 pt-2 pb-2 rounded-pill text-white"
        style={{ width: "max-content", cursor: "pointer" }}
        onClick={() => {
          // Handle marks
          submitQuestionAnswer(
            hasMarks() === true ? selectedQuestion.score : 0, // To be calculated
            selectedOptions
          );
        }}
      >
        {isLastQuestion === true ? "Save & Submit the Test" : "Save & Continue"}
      </div>
    </div>
  );
};

export default HandleMatching;

const testData = {
  selectedOptions: [
    {
      id: "17915",
      questionId: "9874",
      optionNumber: "A",
      en: "Role clarity",
      fr: "Clarté du rôle",
      kn: "",
      matchEn: "Clearly define new roles and responsibilities.",
      matchFr: "Définir clairement les nouveaux rôles et responsabilités.",
      matchKn: "",
      rateValue: null,
      isCorrect: false,
      createdOn: "2024-11-26T15:29:16.607Z",
      createdBy: null,
    },
    {
      id: "18314",
      questionId: "9874",
      optionNumber: "B",
      en: "Support systems",
      fr: "Systèmes de soutien",
      kn: "",
      matchEn: "Provide resources and assistance to help team members adjust.",
      matchFr:
        "Fournir des ressources et de l'aide pour aider les membres de l'équipe à s'adapter.",
      matchKn: "",
      rateValue: null,
      isCorrect: false,
      createdOn: "2024-11-26T15:29:17.217Z",
      createdBy: null,
    },
    {
      id: "18669",
      questionId: "9874",
      optionNumber: "C",
      en: "Team collaboration",
      fr: "Collaboration d'équipe",
      kn: "",
      matchEn: "Foster teamwork and mutual support among team members.",
      matchFr:
        "Favoriser le travail d'équipe et le soutien mutuel entre les membres de l'équipe.",
      matchKn: "",
      rateValue: null,
      isCorrect: false,
      createdOn: "2024-11-26T15:29:17.787Z",
      createdBy: null,
    },
    {
      id: "18990",
      questionId: "9874",
      optionNumber: "D",
      en: "Regular updates",
      fr: "Mises à jour régulières",
      kn: "",
      matchEn: "Keep the team informed about changes and progress.",
      matchFr: "Tenez l'équipe informée des changements et des progrès.",
      matchKn: "",
      rateValue: null,
      isCorrect: false,
      createdOn: "2024-11-26T15:29:18.243Z",
      createdBy: null,
    },
  ],
  selectedData: [
    {
      optionNumber: "A",
      matchEn: "",
      matchFr: "",
      matchKn: "",
    },
    {
      optionNumber: "B",
      matchEn: "",
      matchFr: "",
      matchKn: "",
    },
    {
      optionNumber: "C",
      matchEn: "",
      matchFr: "",
      matchKn: "",
    },
    {
      optionNumber: "D",
      matchEn: "",
      matchFr: "",
      matchKn: "",
    },
  ],
};
