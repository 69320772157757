import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import loadingGif from "./assets/loading.gif";
import TopProgressBar from "./applicant/components/comm/TopProgressBar";
import ToastifyMessage from "./applicant/components/comm/ToastifyMessage";
import Home from "./home/index";
import Header from "./applicant/components/comm/Header";
import Footer from "./applicant/components/comm/Footer";

import LoginModal from "./home/components/LoginModal";
import RegisterModal from "./home/components/RegisterModal";

import { getUser, getEnv } from "../store/comm/actions";
import { connect } from "react-redux";

import ApplicantLayout from "./applicant/index";

import { isEmpty } from "lodash";
import OfflineMessage from "./applicant/components/comm/OfflineMessage";
import ResetPassword from "./home/ResetPassword";
import { Alert } from "@mui/material";
import EnvMessage from "./applicant/components/comm/EnvMessage";

function App(props) {
  const { env, user, loading, getUser, isFetchingUser, getEnv } = props;

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    getEnv();
    getUser(history, location.pathname);
  }, []);

  // useEffect(() => {
  //   if (isEmpty(user) && !isFetchingUser) return history.push("/");
  // }, []);

  const [drawerState, setDrawerState] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [showRegisterDialog, setShowRegisterDialog] = useState(false);

  // useEffect(() => {
  //   if (!!showLoginDialog) setShowRegisterDialog(false);
  //   if (!!showRegisterDialog) setShowLoginDialog(false);
  // }, [showLoginDialog, showRegisterDialog]);

  return (
    <>
      {isFetchingUser && (
        <div className="loader ">
          <img src={loadingGif} height="128" alt="loading" />
        </div>
      )}

      {!isFetchingUser && (
        <div id="main" style={{ overflowX: "visible" }}>
          {/* <div className="w-100 border-bottom border-danger">
            <Alert severity="error" onClose={() => {}}>
              You have been blacklisted!
            </Alert>
          </div> */}

          <TopProgressBar />
          <ToastifyMessage />

          {!!env && env !== "prod" && <EnvMessage env={env} />}

          <OfflineMessage />

          {!location.pathname.includes("/exam-portal") && (
            <Header
              drawerState={drawerState}
              setDrawerState={setDrawerState}
              showLoginDialog={showLoginDialog}
              setShowLoginDialog={setShowLoginDialog}
              showRegisterDialog={showRegisterDialog}
              setShowRegisterDialog={setShowRegisterDialog}
            />
          )}

          <div className="mt-2">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/applicant" component={ApplicantLayout} />
              <Route path="/reset-password" component={ResetPassword} />
            </Switch>
          </div>

          {!location.pathname.includes("/exam-portal") && <Footer />}

          {!location.pathname.includes("/exam-portal") && showLoginDialog && (
            <LoginModal
              showDialog={showLoginDialog}
              setShowDialog={setShowLoginDialog}
              showRegisterDialog={showRegisterDialog}
              setShowRegisterDialog={setShowRegisterDialog}
            />
          )}
          {!location.pathname.includes("/exam-portal") &&
            showRegisterDialog && (
              <RegisterModal
                showDialog={showRegisterDialog}
                setShowDialog={setShowRegisterDialog}
                showLoginDialog={showLoginDialog}
                setShowLoginDialog={setShowLoginDialog}
              />
            )}
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ user, loading, isFetchingUser, env }) => {
  return { user, loading, isFetchingUser, env };
};
export default connect(mapStateToProps, { getUser, getEnv })(App);
