import React, { useState } from "react";
import axios from "axios";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Rating,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";

const FeedbackFormDialog = () => {
  const [open, setOpen] = useState(false);
  const [rating, setRating] = useState(null);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRating(null);
    setMessage("");
  };

  const handleSubmit = async() => {
    console.log("Feedback submitted:", { rating, message });
    const advertisementId = 'advertisementId';
    const subject = 'subject';
    const description = message;
    const rateValue = rating;
    const feedbackdata = {advertisementId , subject, description, rateValue}
    try {
      const { data: response } = await axios.post(
        "/api/psychometric-test/addafeedback",
        feedbackdata
      );
      if(response){
        handleClose();
        setSuccessMessage(false);
      }
    } catch (error) {
      
    }
    //{advertisementId, subject, description, rateValue}
    // Simulate form submission or handle logic here

    // Show success message
    setSuccessMessage(true);

    // Close dialog after a delay (e.g., 2 seconds)
    setTimeout(() => {
      handleClose();
      setSuccessMessage(false); // Hide success message after closing dialog
    }, 2000);
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleOpen}
        className="rounded-pill w-100"
      >
        Leave The Feedback
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please leave your feedback by rating your experience and providing
            any additional comments.
          </DialogContentText>

          <Rating
            name="feedback-rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
            }}
            precision={0.5}
          />

          <TextField
            autoFocus
            margin="dense"
            id="feedback-message"
            label="Your Message"
            type="text"
            fullWidth
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for success message */}
      <Snackbar
        open={successMessage}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="success" variant="filled">
          Feedback submitted successfully!
        </Alert>
      </Snackbar>
    </div>
  );
};

export default FeedbackFormDialog;
