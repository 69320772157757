import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Divider,
  ListItemIcon,
} from "@mui/material";

import { connect } from "react-redux";
import logoLG from "../../../assets/logo-lg.png";
import logoSM from "../../../assets/logo-sm.png";
import defaultProfileImage from "../../../assets/default-profile.jpg";
import { logout } from "../../../../store/comm/actions";
import colors from "../../../utils/colors";
import { isEmpty } from "lodash";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import MenuBar from "./MenuBar";
import { Logout } from "@mui/icons-material";
import FeedRoundedIcon from "@mui/icons-material/FeedRounded";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const Header = (props) => {
  const {
    user,
    loading,
    drawerState,
    setDrawerState,
    setShowLoginDialog,
    setShowRegisterDialog,
    logout,
  } = props;

  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [changePassword, setChangePassword] = useState(false);

  return (
    <>
      <Box sx={{ flexGrow: 1 }} id="checking">
        <AppBar position="static" elevation={0} className="app-bar">
          <Toolbar className="px-3 d-flex justify-content-between">
            <div className="d-flex align-items-center">
              <img
                style={{
                  width: "145px",
                }}
                src={logoLG}
                className=" mr-2 d-none d-md-inline"
                alt="Logo"
              />
              <img
                style={{
                  width: "50px",
                }}
                src={logoSM}
                className=" mr-2  d-inline d-md-none"
                alt="Logo"
              />
            </div>
            <Typography
              component="h3"
              style={{ color: colors.blueLogo }}
              className="font-weight-bold d-none d-md-block"
            >
              E-RECRUITMENT
            </Typography>

            {!isEmpty(user) ? (
              <>
                <div className="d-flex align-items-center">
                  <div className="text-primary  d-flex">
                    <strong className=" d-flex align-items-center">
                      <span
                        style={{ borderRadius: "0px" }}
                        className="role text-truncate badge badge-pill border"
                      >
                        {user.lastName} {user.firstName}
                      </span>
                    </strong>

                    <ArrowLeftIcon />
                  </div>
                  <Avatar
                    alt={user.lastName + " " + user.firstName}
                    src={`${
                      !!user.profileImage
                        ? "data:image/png;base64," + user.profileImage
                        : defaultProfileImage
                    } `}
                    // style={{ objectFit: "none" }}
                    className={`cursor-pointer`}
                    onClick={(e) => !loading && handleClick(e)}
                    aria-controls={open ? "account-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                  />
                  <Menu
                    anchorEl={anchorEl}
                    id="account-menu"
                    open={open}
                    onClose={handleClose}
                    onClick={handleClose}
                    PaperProps={{
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.8,
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&:before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    }}
                    transformOrigin={{ horizontal: "right", vertical: "top" }}
                    anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                  >
                    <MenuItem disabled className="text-uppercase">
                      {user.lastName} {user.firstName}
                    </MenuItem>
                    <Divider />

                    <MenuItem
                      onClick={() => logout(history)}
                      className="text-danger"
                    >
                      <ListItemIcon>
                        <Logout fontSize="small" className="text-danger" />
                      </ListItemIcon>
                      Logout
                    </MenuItem>
                  </Menu>
                </div>
              </>
            ) : (
              <div className="d-flex align-items-center">
                <Button
                  disableElevation
                  variant="contained"
                  size="small"
                  onClick={() => setShowLoginDialog(true)}
                  className="mr-1"
                  disabled={loading}
                >
                  Login
                </Button>
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => setShowRegisterDialog(true)}
                  disabled={loading}
                >
                  Register
                </Button>
              </div>
            )}
          </Toolbar>
        </AppBar>

        {!isEmpty(user) && <MenuBar />}
      </Box>
    </>
  );
};

const mapStateToProps = ({ user, loading }) => {
  return {
    user,
    loading,
  };
};
export default connect(mapStateToProps, {
  logout,
})(Header);
